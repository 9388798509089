import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let MenuItem = class MenuItem extends LitElement {
    constructor() {
        super(...arguments);
        this.icon = null;
        this.action = () => { };
        this.title = "";
    }
    render() {
        return html `
            
            <div class="link" @click=${this.action}>
                <span class="iconlink">
                    <fa-icon  
                        .icon=${this.icon}
                        title=${this.title}>
                    </fa-icon>
                </span>
                <span class="textlink">
                    ${this.title}
                </span>
            </div>
            
        `;
    }
};
MenuItem.styles = css `
        :host {
            display: block;
            position: static;
        }
        :host:hover {
            cursor: pointer;
        }
        div.link {
            display: flex;
            flex-direction: row;
            border-radius: var(--digilean-border-radius, 8px);
            background: var(--digilean-secondary-background);
            text-decoration: none;
        }
        div.link:hover {
            background: var(--digilean-selected);
        }
        div.link .iconlink {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            height: 2.5rem;
            flex-basis: 2.5rem;
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: var(--digilean-border-radius, 8px) 0 0 var(--digilean-border-radius, 8px);
        }
        div.link fa-icon {
            color: var(--digilean-icon-color, var(--digilean-secondary-text));
            /* --digilean-fa-icon-width: 1.5rem;
            --digilean-fa-icon-height: 1.5rem; */
        }
        .textlink {
            color: var(--digilean-secondary-text);
            display: inline-block;
            vertical-align: center;
            line-height: 2.5rem;
            font-size: 1.2rem;
            flex-basis: 5rem;
            flex-grow: 1;
            flex-shrink: 0;
            border-radius: 0 var(--digilean-border-radius, 8px) var(--digilean-border-radius, 8px) 0;
        }
    `;
__decorate([
    property({ attribute: false })
], MenuItem.prototype, "icon", void 0);
__decorate([
    property({ attribute: false })
], MenuItem.prototype, "action", void 0);
__decorate([
    property({ attribute: true })
], MenuItem.prototype, "title", void 0);
MenuItem = __decorate([
    customElement('menu-item')
], MenuItem);
export { MenuItem };

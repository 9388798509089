import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { observe } from "@common/directives/observableDirective";
import { userProfile } from "@common/stores/userStore";
let LoggedInProfileImageViewer = class LoggedInProfileImageViewer extends LitElement {
    constructor() {
        super(...arguments);
        this.initials = false;
    }
    render() {
        return observe(userProfile, (userProfile) => {
            if (userProfile) {
                return html `
                    <profile-image-viewer .user=${userProfile.user} .initials=${this.initials}></profile-image-viewer>
                `;
            }
            return html `
                <span>whut</span>
            `;
        });
    }
};
LoggedInProfileImageViewer.styles = css `
        :host {
            display: block;
        }
    `;
__decorate([
    property({ attribute: false })
], LoggedInProfileImageViewer.prototype, "initials", void 0);
LoggedInProfileImageViewer = __decorate([
    customElement('logged-in-profile-image-viewer')
], LoggedInProfileImageViewer);
export { LoggedInProfileImageViewer };

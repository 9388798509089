import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { faEllipsisV, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
let PopdownMenu = class PopdownMenu extends LitElement {
    constructor() {
        super(...arguments);
        this._show = false;
    }
    toggle() {
        this._show = !this._show;
        this.requestUpdate();
    }
    render() {
        return html `
            <slot name="button"  @click=${this.toggle}>
                <fa-icon .icon=${faEllipsisV}></fa-icon>
            </slot>
            
            <div class="popdown ${this._show ? 'show' : ''}">
                <div class="top">
                    <fa-icon .icon=${faTimesCircle} @click=${this.toggle}></fa-icon>
                </div>
                <div class="content">
                    <slot name="content">no content</slot>
                </div>
            </div>
        `;
    }
};
PopdownMenu.styles = css `
        :host {
            display: block;
            position: relative;
            background: var(--digilean-secondary-background);
            height: var(--digilean-image-height, 1.5rem);
            width: var(--digilean-image-width, 2rem);
            --digilean-fa-icon-width: 1rem;
            --digilean-fa-icon-height: 1rem;
        }
        fa-icon:hover {
            cursor: pointer;
        }
        div.popdown {
            display: none;
            flex-direction: column;
            position: absolute;
            width: 200px;
            z-index: 10000;
            top: 0;
            right: 0;
            border-radius: var(--digilean-border-radius, 8px);
            background: var(--digilean-secondary-background);
            box-shadow: var(--digilean-box-shadow-hover);
        }
        div.popdown.show {
            display: flex;
        }
        div.top {
            display: flex;
            position: static;
            flex-direction: row;
            justify-content: flex-end;
        }
        div.content {
            position: static;
        }
        a-icon:hover {
            cursor: pointer;
        }
    `;
PopdownMenu = __decorate([
    customElement('popdown-menu')
], PopdownMenu);
export { PopdownMenu };

import { cloneDeep } from "lodash-es";
import { BehaviorSubject } from "rxjs";
export class SvelteSubject extends BehaviorSubject {
    set(value) {
        super.next(value);
    }
    get() {
        const value = super.getValue();
        return cloneDeep(value);
    }
}
export class StringAppendableSubject extends SvelteSubject {
    append(app) {
        const val = super.get();
        super.set(`${val}${app}`);
    }
}
export class TogglableSubject extends SvelteSubject {
    toggle() {
        const val = super.get();
        super.set(!val);
    }
}

<script lang="ts">
    import type { Deviation } from "@api"
    export let deviation: Deviation = {
        id: 0,
        title: "loading"
    }
    import { faGlasses } from "@fortawesome/free-solid-svg-icons"
</script>

    <div class="topbar">
        <deviation-state status={deviation.status}></deviation-state>
        <span>{deviation.createdByUser}</span>
        <date-viewer date={deviation.incidentDate} fontsize="x-small"></date-viewer>
    </div>
    <div class="header">
        <div class="placeholder"></div>
        <h2>{deviation.title}</h2>
        <fa-icon icon={faGlasses}></fa-icon>
    </div>
    <div class="description">
        {@html deviation.text}
    </div>

<style>
    .description {
        background: var(--digilean-secondary-background);
        color: var(--digilean-secondary-text);
        margin: 0.8rem;
        padding: 0.6rem;
    }
    div.topbar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 1rem;
        color: var(--digilean-secondary-text);
    }
    
    div.header {
        height: 3rem;
        line-height: 3rem;
        vertical-align: middle;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    h2 {
        padding: 0;
        margin: 0;
    }
    .placeholder {
        display: inline-block;
        width: 1rem;
    }
</style>
<script lang="ts">
	import { onMount } from "svelte";
	import { openMainMenu, appInitialized, updatedVersion, doUpdateVersion, isDarkTheme, loadingOrSaving } from "@common/stores/appstate"
	import { authUser, userProfile } from "@common/stores/userStore"
	import { gotoPath } from "@app/routing/router";
	import { initAuth } from "@common/authentication/authenticator"
	import "@app/routing/manager"
	import "@app/routing/pagestate"
	import "@app/swloader"

	import {initSignalrWorker} from "@app/workers/signalrWorkerService"
	import Container from "@app/routing/Container.svelte"
	import Link from "@app/routing/Link.svelte"
	import SideMenu from "@app/components/SideMenu.svelte"
	import MainMenu from "@app/MainMenu.svelte"
	import Landing from "@app/Landing.svelte"
	import { faBars, faSync } from "@fortawesome/free-solid-svg-icons"
	import { faPlusSquare } from "@fortawesome/free-regular-svg-icons"
	
	import NetworkState from "@app/components/NetworkState.svelte"
	onMount(() => {
		isDarkTheme.subscribe(val => {
            if (val)
                document.body.classList.add("dark-theme")
            else
                document.body.classList.remove("dark-theme")
        })
		initAuth()
		initSignalrWorker()
        
		gotoPath(window.location.pathname)
    })
	
	const openMenu = () => {
		openMainMenu.next(true)
	}
	const goToNew = () => {
		gotoPath("/new")
	}
	const goToNetwork = () => {
		gotoPath("/network")
	}
</script>

<style>
    
	main {
		text-align: center;
		padding: 0;
		margin: 0 auto;
		width: 1024px;
		display: flex;
		flex-direction: column;
		flex-basis: auto;
		flex-grow: 1;
	}
	header {
		display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
		gap: 0.5rem;

		padding: 0.5rem 0.5rem 0.5rem 1rem;
		flex-basis: auto;
	}
	section {
		flex-basis: auto;
		flex-grow: 1;
	}
	h1 {
		padding: 0;
		margin: 0 0 0 1rem;
		font-size: 1.5rem;
		font-weight: 300;
		color: var(--digilean-main-menu-text);
	}
	profile-image-viewer {
		height: 2rem;
		width: 2rem;
		line-height: 2rem;
		font-size: 0.8rem;
	}
	.update-version {
		display: none;
	}
	.update-version.show {
		display: inline-block;
	}
	
	.spaceholder {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}
	.network-state {
		display: inline-block;
	}
	fa-icon.loading {
		visibility: hidden;
	}
	fa-icon.loading.show {
		visibility: visible;
		animation: spin-animation 1s infinite linear;
	}
	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
	@media only screen and (max-width: 1024px) {
        main {
            width: 100%;
        }
    }
	
</style>

{#if $appInitialized && $authUser.loggedIn}
	<main>
		<header>
			<fa-icon class="size30" icon={faBars} on:click={openMenu}></fa-icon>
			<Link route="/">
				<h1>DigiLEAN</h1>
			</Link>
			<div class="network-state" on:click={goToNetwork}>
				<NetworkState />
			</div>
			
			<fa-icon class="size20 primary-button loading" class:show={$loadingOrSaving} icon={faSync}></fa-icon>
			
			<div class="spaceholder">&nbsp;</div>
			
			<button class="update-version" class:show={$updatedVersion} on:click={() => doUpdateVersion.next(true)}>New version</button>
			
			{#if $userProfile && $userProfile.user}
				<profile-image-viewer user={$userProfile.user} initials={true}></profile-image-viewer>
			{/if}
			
			<fa-icon class="size40 primary-button" icon={faPlusSquare} on:click={goToNew}></fa-icon>
		</header>
		<section>
			<Container />
		</section>
		<SideMenu>
			<MainMenu />
		</SideMenu>
		<toast-messages></toast-messages>
	</main>
{:else}
	<Landing />
{/if}

import { BehaviorSubject } from "rxjs";
const permissionSubject = new BehaviorSubject("default");
export const notificationPermission = permissionSubject.asObservable();
export const setPermission = (p) => permissionSubject.next(p);
const notificationsSubject = new BehaviorSubject({ title: "" });
export const notificationsQueue = notificationsSubject.asObservable();
export const queueNotification = (title, body, taskId) => {
    var notReq = { title, body, taskId };
    notificationsSubject.next(notReq);
};

import { authUser } from "@common/stores/userStore";
import { setSignalRConnection } from "@common/stores/signalr";
import { queueNotification } from "@common/stores/notifications";
import { isOnline } from "@common/stores/appstate";
const sworkerUrl = "/workers/signalrSharedWorker.js";
const dworkerUrl = "/workers/signalrDedicatedWorker.js";
let isSharedWorkerSupported = false;
if (window.SharedWorker)
    isSharedWorkerSupported = true;
let userAuth;
let worker;
export const initSignalrWorker = () => {
    if (isSharedWorkerSupported)
        initSharedWorker();
    else
        initDedicatedWorker();
    authUser.subscribe(user => {
        userAuth = user;
        if (user.loggedIn) {
            sendSetupToWorker(false);
        }
    });
};
const initSharedWorker = () => {
    worker = new SharedWorker(sworkerUrl);
    worker.port.onmessage = messageEvent;
    window.addEventListener("beforeunload", (e) => {
        const sharedWorker = worker;
        if (sharedWorker && sharedWorker.port) {
            sendSetupToWorker(true);
        }
    });
};
const initDedicatedWorker = () => {
    worker = new Worker(dworkerUrl);
    worker.onmessage = messageEvent;
};
const messageEvent = (event) => {
    console.log(event.data);
    const data = event.data;
    switch (data.type) {
        case "state":
            stateEventReceived(data.message);
            break;
        case "event":
            signalREventReceived(data.message);
            break;
        default:
            console.log(`unknown event type ${data.type}`);
            break;
    }
};
const sendSetupToWorker = (closing) => {
    if (!userAuth || !userAuth.token)
        return;
    let message = {
        closing,
        token: userAuth.token
    };
    let msg = {
        type: "setup",
        message
    };
    if (isSharedWorkerSupported) {
        const sharedWorker = worker;
        sharedWorker.port.postMessage(msg);
    }
    else {
        const dedWorker = worker;
        dedWorker.postMessage(msg);
    }
};
const stateEventReceived = (state) => {
    setSignalRConnection(state);
};
const signalREventReceived = (msg) => {
    switch (msg.eventName) {
        case "Notification":
            notificationEvent(msg.payload);
            break;
        case "TaskAssigned":
            taskAssignedEvent(msg.payload);
        default:
            break;
    }
};
const taskAssignedEvent = (ta) => {
    if (ta.userId == userAuth.userId)
        queueNotification("task assigned", `Task assigned by ${ta.assignedByName}`, ta.taskId);
};
const notificationEvent = (notification) => {
    console.log(notification);
    //queueNotification("notification")
};
isOnline.subscribe(isonline => {
    if (isonline)
        sendSetupToWorker(true);
});

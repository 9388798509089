<script lang="ts">
    import {onMount, onDestroy} from "svelte"
    import { notificationPermission } from "@common/stores/notifications"
    import { signalRConnection } from "@common/stores/signalr"
    import { isOnline, isPageVisible } from "@common/stores/appstate"
    import { requestNotificationsPermission, sendNotification } from "@common/services/notificationsApi"
    import NetworkState from "@app/components/NetworkState.svelte"
    

    import { getReqQueue } from "@common/services/indexDb"
    import type { Subscription } from "rxjs"

    let sub: Subscription | null
    let visible = false
    onMount(() => {
        sub = isPageVisible.subscribe({
            next: (val) => {
                setTimeout(() => {
                    visible = val
                }, 500)
            },
            error: (err) => console.log(err)
        })
    })
    onDestroy(() => sub.unsubscribe())

    let networkInfo = ""
    let getNetworkInfo = () => {
        if (!navigator.connection) {
            networkInfo = "N/A"
            return
        }
        const connection = navigator.connection
        let conState = {
            ///@ts-ignore
            downlink: connection.downlink,
            ///@ts-ignore
            effectiveType: connection.effectiveType,
            ///@ts-ignore
            rtt: connection.rtt,
            ///@ts-ignore
            saveData: connection.saveData
        }
        networkInfo = JSON.stringify(conState, null, 4)
    }
</script>
<style>
    #visible {
        display: inline-block;
        background: red;
        transition: background 1s linear;
    }
    #visible.visible {
        background: green;
    }
</style>
<article>

    <h3>Notification state</h3>
    Permission: {$notificationPermission}<br>
    <button on:click={requestNotificationsPermission} disabled={$notificationPermission === "granted"}>Request notification permission</button><br>
    <button on:click={sendNotification}>Send notification</button>
    <br>
    <button on:click={getReqQueue}>getQueue</button>
    <h3>Network state</h3>
    <NetworkState />
    <p>
        network is online: {$isOnline}<br>
        SignalR is connected:{$signalRConnection.isConnected}<br>
        connectionId: {$signalRConnection.id}<br>
        tabsCount: {$signalRConnection.tabsCount}
    </p>
    <button on:click={getNetworkInfo}>Get network info</button><br>
    <textarea cols="30" rows="10" bind:value={networkInfo}></textarea>
    <h3>visibility</h3>
    <p>
        <span id="visible" class:visible>visible: {$isPageVisible}</span>
    </p>

</article>

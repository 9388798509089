import routes from "./routes";
import NotFound from "@app/views/404.svelte";
import pathBreaker from "./pathBreaker";
import { gotoPath, curRoutePath, curRoute } from "./router";
import { authUser } from "@common/stores/userStore";
let userAuth;
authUser.subscribe(user => userAuth = user);
export const findComponent = async (path) => {
    const r = await findRoute(path);
    return r.component;
};
export const findRoute = async (fullpath) => {
    const bRoute = pathBreaker.getRoute(fullpath);
    const route = routes.filter(r => r.path === bRoute.path);
    const nf404 = notFound(fullpath);
    if (!route || route.length === 0) {
        return nf404;
    }
    const r = route[0];
    if (r.mustBeLoggedIn && !userAuth.loggedIn) {
        localStorage.setItem("postLoginPath", fullpath);
        gotoPath("/signin");
        return;
    }
    if (r.admin && !userAuth.isAdmin)
        return nf404;
    if (r.init) {
        r.init(bRoute.param);
    }
    return {
        path: r.path,
        component: r.component,
        param: bRoute.param,
        action: bRoute.action
    };
};
const notFound = (path) => {
    return {
        path,
        component: NotFound
    };
};
curRoutePath.subscribe(async (path) => {
    const route = await findRoute(path);
    curRoute.set(route);
});

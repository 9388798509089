<script lang="ts">
    import type { UserTask } from "@api"
    import { gotoPath } from "@app/routing/router"
    //import { faCheckSquare, faComment, faPaperclip } from "@fortawesome/free-solid-svg-icons"
    export let task: UserTask

    let gotoUserTaskView = () => {
        let path = `/task/${task.id}`
        if (task.taskType == "Improvement" && task.entityType == "Improvement")
            path = `/improvement/${task.id}`
        
        gotoPath(path)
    }
</script>
<style>
    .task-wrapper {
        display: flex;
        flex-direction: row;
        color: var(--digilean-secondary-text);
        cursor: pointer;
        margin: 0.5rem 0;
    }
    /* .task-wrapper:hover {
        background: var(--digilean-transparent-background);
        color: var(--digilean-main-menu-text);
    } */
    .checkbox, .main, .dateand {
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        gap: 1rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .checkbox {
        flex-basis: 12%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .main {
        flex-basis: 60%;
    }
    .dateand {
        flex-basis: 25%;
    }
    .category {
        font-weight: 600;
        font-size: x-small;
    }
    
</style>
<div class="task-wrapper">
    <div class="checkbox">
        <digilean-tri-state-box />
    </div>
    <div class="main" on:click={() => gotoUserTaskView()}>
        <div class="title">
            {task.title}
        </div>
        <div class="category">
            {#if task.boardName}
                {task.boardName} |
            {/if}
            {#if task.columnCategory}
                {task.columnCategory} |
            {/if}
            {#if task.rowCategory}
                {task.rowCategory}
            {/if}
        </div>
    </div>
    <div class="dateand">
        <div class="date">
            <date-viewer date={task.boardDate} fontsize="x-small"></date-viewer>
        </div>
        <div class="type">
            {task.taskType}
        </div>
    </div>
</div>
import { formatLoggedInUser } from "@common/services/userAuthService";
import { cloneDeep } from "lodash";
import { BehaviorSubject } from "rxjs";
import { clonedObservable } from "@common/stores/reactive";
const authUserSubject = new BehaviorSubject({ loggedIn: false });
export const authUser = clonedObservable(authUserSubject);
const userProfileSubject = new BehaviorSubject({});
export const userProfile = userProfileSubject.asObservable();
export const setAuthUser = async (user) => {
    if (user) {
        const authuser = formatLoggedInUser(user);
        authUserSubject.next(authuser);
    }
    else {
        setUserLoggedOut();
    }
};
export const setUserProfile = (userProfile) => {
    userProfileSubject.next(userProfile);
};
export const setUserLoggedOut = () => {
    authUserSubject.next({ loggedIn: false });
};
const getCurrentUserClone = () => {
    const current = authUserSubject.getValue();
    const clone = cloneDeep(current);
    return clone;
};

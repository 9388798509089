<script lang="ts">
    import {onMount} from "svelte"
    
    import type { ImprovementSuggestion } from "@api"
    import { getAttAndThumbnails, uploadFileSuggestion, getCanEdit } from "@common/services/suggestionService"
    import { faGlasses, faCamera, faImages, faVideo } from "@fortawesome/free-solid-svg-icons"
    import type { FileView } from "@common/model/types"
    export let suggestion: ImprovementSuggestion = {
        attachments: []
    }
    
    let canEdit = false
    let images: FileView[] = []
    let files: FileView[] = []
    const getAttachments = async () => {
        const atts = await getAttAndThumbnails(suggestion.attachments)
        images = atts.images
        files = atts.files
    }
    onMount(async () => {
        getAttachments()
        canEdit = await getCanEdit(suggestion.id)
    })
    const uploadFile = async (e: any) => {
        const inputField = e.target
        if (inputField.files && inputField.files[0]) {
            inputField.classList.add("uploading")
            inputField.disabled = true
            const file = inputField.files[0]
            const newAttachment = await uploadFileSuggestion(suggestion.id, file)
            inputField.classList.remove("uploading")
            inputField.disabled = false
            inputField.value = ""
            suggestion.attachments.push(newAttachment)
            getAttachments()
        }
    }
</script>
<style>
    
</style>
{#each images as img}
    <image-viewer url={img.url} alt={img.filename}></image-viewer>
{/each}
{#each files as file}
    <document-file-link extension={file.extension} filename={file.filename} url={file.url}></document-file-link>
{/each}
<br>
{#if canEdit}
    <div class="uploaders">
        <div class="button">
            <label for="cameraInput"><fa-icon icon={faCamera}></fa-icon></label>
            <input id="cameraInput" class="noshow" type="file" accept="image/*" capture="environment" on:change={uploadFile} />
        </div>
        <div class="button">
            <label for="galleryInput"><fa-icon icon={faImages}></fa-icon></label>
            <input id="galleryInput" class="noshow" type="file" accept="image/*" capture="filesystem" on:change={uploadFile} />
        </div>
        <div class="button">
            <label for="videoInput"><fa-icon icon={faVideo}></fa-icon></label>
            <input id="videoInput" class="noshow" type="file" accept="video/*" capture="environment" on:change={uploadFile} />
        </div>
    </div>
{:else}
    <fa-icon icon={faGlasses}></fa-icon>
{/if}
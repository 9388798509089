<script lang="ts">
    import { faCheckSquare, faLightbulb } from "@fortawesome/free-regular-svg-icons"
    import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
</script>
<style>
    article {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: var(--digilean-primary-background);
        color: var(--digilean-primary-text);
        padding: 0.8rem;
        gap: 0.8rem;
    }
    .newtask {
        --digilean-icon-background: var(--digilean-blue-sky-lighter);
        --digilean-icon-color: var(--digilean-primary-link);
    }
    .suggestion {
        --digilean-icon-background: var(--digilean-green-bright);
        --digilean-icon-color: var(--digilean-green);
    }
    .incident {
        --digilean-icon-background: var(--digilean-pink-light);
        --digilean-icon-color: var(--digilean-danger);
    }
</style>
<article>
    <new-button icon={faCheckSquare} class="newtask" title="New task" url="/new-task"></new-button>
    <new-button icon={faLightbulb} class="suggestion" title="New suggestion" url="/new-suggestion"></new-button>
    <new-button icon={faExclamationTriangle} class="incident" title="New incident" url="/new-incident"></new-button>
</article>

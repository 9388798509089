<script lang="ts">
    import type { UserTask } from "@api"
    import Task from "./UserTask.svelte"
    export let tasks: UserTask[]
    export let title = ""
    export let color = ""
</script>
<style>
    section {
        display: flex;
        flex-direction: column;
        color: var(--digilean-text-on-dark);
    }
    hr {
        border-top: 1px solid var(--digilean-wizard-unselected);
        margin: 0 0.5rem;
    }
</style>
<section>
    {#if tasks && Array.isArray(tasks)}
        <header style="background: var({color})">
            <p class="title">{title}</p>
            <p class="counter">{tasks.length} Tasks</p>
        </header>
        {#each tasks as task}
            <Task task={task} />
            <hr>
        {/each}
    {/if}
</section>
<script lang="ts">
    import {onMount} from "svelte"
    
    import type { Deviation } from "@api"
    import { getAttAndThumbnails } from "@common/services/deviationService"
    //import { faGlasses, faCamera, faImages, faVideo } from "@fortawesome/free-solid-svg-icons"
    import type { FileView } from "@common/model/types"
    export let deviation: Deviation = {
        attachments: []
    }
    
    // let inputField: HTMLInputElement
    let canEdit = false
    let images: FileView[] = []
    let files: FileView[] = []
    
    onMount(async () => {
        const atts = await getAttAndThumbnails(deviation.attachments)
        images = atts.images
        files = atts.files
    })

</script>
<style>
    
</style>
{#each images as img}
    <image-viewer url={img.url} alt={img.filename}></image-viewer>
{/each}
{#each files as file}
    <document-file-link extension={file.extension} filename={file.filename} url={file.url}></document-file-link>
{/each}
<br>
import { AsyncDirective, directive } from "lit/async-directive.js";
import { noChange } from "lit";
//
// observable directive that takes a template render as callback
//
class ObservableDirective extends AsyncDirective {
    // When the observable changes, unsubscribe to the old one and
    // subscribe to the new one
    render(observable, callback) {
        var _a;
        this.callback = callback;
        if (this.observable !== observable) {
            (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
            this.observable = observable;
            if (this.isConnected) {
                this.subscribe();
            }
        }
        return noChange;
    }
    // call setvalue with the template render and value as input
    subscribe() {
        var _a;
        this.subscription = (_a = this.observable) === null || _a === void 0 ? void 0 : _a.subscribe((v) => {
            var _a;
            this.setValue((_a = this.callback) === null || _a === void 0 ? void 0 : _a.call(this, v));
        });
    }
    disconnected() {
        var _a;
        (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    reconnected() {
        this.subscribe();
    }
}
export const observe = directive(ObservableDirective);

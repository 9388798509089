import { WebStorageStateStore, Log, UserManager } from "oidc-client";
import { appInitialized } from "@common/stores/appstate";
import { setAuthUser } from "@common/stores/userStore";
import config from "@common/config";
const logPrefix = "DigiLeanOIDC::";
const rootPath = window.location.origin;
let oicdConfig = {
    authority: config.authUrl,
    client_id: "webclient",
    redirect_uri: `${rootPath}/callback.html`,
    response_type: "code",
    scope: "openid profile api.read",
    post_logout_redirect_uri: rootPath,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    accessTokenExpiringNotificationTime: 60,
    silentRequestTimeout: 20000,
    automaticSilentRenew: false,
    silent_redirect_uri: `${rootPath}/silent-renew.html`
};
Log.logger = console;
let manager = new UserManager(oicdConfig);
const addUserLoaded = (fn) => {
    manager.events.addUserLoaded(fn);
};
const addUserSignedOut = (fn) => {
    manager.events.addUserSignedOut(fn);
};
let events = {
    addUserLoaded,
    addUserSignedOut
};
const setupEvents = () => {
    manager.events.addUserLoaded((user) => {
        logDebug(`user ${user.profile.email} loaded, happens on login and token renewal`);
        setAuthUser(user);
    });
    manager.events.addUserUnloaded(() => {
        logDebug("user unloaded. Session terminated. user: ");
    });
    manager.events.addUserSignedIn(() => {
        logDebug(`user signed in`);
    });
    manager.events.addUserSignedOut(() => {
        logDebug("user signed OUT, if it was another tab, we need to do it here as well");
        setAuthUser(null);
    });
    manager.events.addAccessTokenExpiring(() => {
        logDebug("access token soon expiring");
        silentRenew();
    });
    manager.events.addAccessTokenExpired(() => {
        logDebug("access token expired. Renew hopefully");
    });
    manager.events.addSilentRenewError(error => {
        logDebug(`error silent renew ${error.message}`);
    });
};
const silentRenew = async () => {
    logDebug("silentRenew:: manual start");
    try {
        const user = await manager.signinSilent();
        logDebug("silentRenew:: renewal successful");
        setAuthUser(user);
        return user;
    }
    catch (err) {
        logError(`silentRenew:: Error from signinSilent: ${err.message}`);
    }
};
const getLoggedInUser = async () => {
    const user = await manager.getUser();
    if (!user || user.expired) {
        log("DigiLEAN auth:: no user means not logged in");
        return login();
    }
    else {
        return user;
    }
};
const login = () => {
    manager.signinRedirect({
        state: window.location.href
    });
};
const initialize = async () => {
    const user = await manager.getUser();
    if (!user || user.expired) {
        log("Not logged in");
        if (!user)
            log("No user");
        else
            log(`User expired=${user.expired}`);
    }
    else {
        setAuthUser(user);
    }
    appInitialized.next(true);
};
const signOut = () => {
    logDebug("sign out of digilean");
    setAuthUser(null);
    manager.signoutRedirect();
};
const log = (msg) => {
    console.log(`${logPrefix} ${msg}`);
};
const logDebug = (msg) => {
    console.debug(`${logPrefix} ${msg}`);
};
const logError = (msg) => {
    console.error(`${logPrefix} ${msg}`);
};
setupEvents();
export default {
    initialize,
    events,
    getLoggedInUser,
    login,
    signOut
};

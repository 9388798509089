import rest from "@common/services/restService";
const personalBoard = {
    name: "Personal board",
    id: 0
};
export const getMemberBoards = async () => {
    let boards = await rest.get("board/getmemberboards");
    boards.unshift(personalBoard);
    return boards;
};
export const getBoardInfo = async (taskId) => {
    const info = await rest.get(`task/getboardInfo/${taskId}`);
    return info;
};
export const getBoardInfoFormatted = async (taskId) => {
    const info = await getBoardInfo(taskId);
    const formatted = formatBoardInfo(info);
    return formatted;
};
const formatBoardInfo = (tbInfo) => {
    if (!tbInfo)
        return "";
    let formatted = tbInfo.boardName;
    if (tbInfo.rowName)
        formatted += ` | ${tbInfo.rowName}`;
    if (tbInfo.categoryName)
        formatted += ` | ${tbInfo.categoryName}`;
    return formatted;
};

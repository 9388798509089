const leftFillNum = (num, targetLength) => {
    return num.toString().padStart(targetLength, "0");
};
export const getInitials = (name) => {
    if (name && name.length > 2) {
        return `${name.charAt(0)}${name.charAt(1)}`.toUpperCase();
    }
    return "US";
};
export const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};
export const getDayOfWeek = (yymmdd) => {
    // 2021-06-17
    const d = new Date(yymmdd);
    const day = d.getDay();
    if (day == 0)
        return 7;
    return day;
};
export const yyyymmddhhmmss = () => {
    const d = new Date();
    const yy = d.getFullYear();
    const mo = leftFillNum(d.getMonth() + 1, 2);
    const da = leftFillNum(d.getDate(), 2);
    const hh = leftFillNum(d.getHours(), 2);
    const mm = leftFillNum(d.getMinutes(), 2);
    const ss = leftFillNum(d.getSeconds(), 2);
    return `${yy}${mo}${da}${hh}${mm}${ss}`;
};
export const yyyymmdd = (date) => {
    if (!date)
        return "";
    const d = new Date(date);
    const yy = d.getFullYear();
    const mo = leftFillNum(d.getMonth() + 1, 2);
    const da = leftFillNum(d.getDate(), 2);
    return `${yy}-${mo}-${da}`;
};
export const debounceEvent = (callback, time = 250, interval) => (...args) => {
    clearTimeout(interval);
    interval = window.setTimeout(() => callback(...args), time);
};

import config from "@common/config";
import rest from "@common/services/restService";
import { authUser, setUserProfile } from "@common/stores/userStore";
const profileImageContainerUrl = `${config.fileStoreUrl}/profile-images`;
authUser.subscribe(async (user) => {
    if (user && user.loggedIn) {
        const up = await getUserProfile();
        setUserProfile(up);
    }
    else {
        setUserProfile({});
    }
});
export const getAllUsers = async () => {
    const all = await rest.get("user");
    return all;
};
export const getUserProfile = async () => {
    const profile = await rest.get("user/getfullprofile");
    if (profile.settings && typeof profile.settings.settings === "string") {
        profile.settings = JSON.parse(profile.settings.settings);
    }
    return profile;
};
export const getUsersProfileImage = async () => {
    const userpi = await rest.get("profile/GetUsersWithProfileImage");
    return userpi;
};
export const getProfileImageFilestore = (imageSize, fileName) => {
    return `${profileImageContainerUrl}/${imageSize}-${fileName}`;
};

<script lang="ts">
    import Link from "@app/routing/Link.svelte"
    export let langCode;
    export let param = "";
    export let base = "";
    export let icon = "";
    
    let route = "";

    $: if (param) {
        route = base;
        if (param) {
            route = `${route}/${param}`;
        }
    }
</script>

<Link route={route} langCode={langCode} icon={icon} background="dark"/>
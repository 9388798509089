<script lang="ts">
    import { myTasks, loadMyTasks } from "@common/stores/taskStore"
    import TaskList from "./TaskList.svelte"
</script>

<style>
    article {
        background: var(--digilean-primary-background);
        color: var(--digilean-primary-text);
    }
</style>
<article on:dblclick={loadMyTasks}>
    <TaskList tasks={$myTasks.today} title="Today" color="--digilean-info" />
    <TaskList tasks={$myTasks.overdue} title="Overdue" color="--digilean-danger"/>
    <TaskList tasks={$myTasks.future} title="Future" color="--digilean-success" />
</article>

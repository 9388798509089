<script lang="ts">
    import {onMount} from "svelte"
    import type { Page } from "@common/model/types"
    export let pages: Page[] = []
    export let currentIndex = 0
    export let base = "/"
    export let param = null
    import SiblingLink from "@app/components/SiblingLink.svelte"
    import { gotoPath } from "@app/routing/router"

    let tabbarEl
	onMount(async () => {
        tabbarEl.addEventListener("MDCTabBar:activated", (e) => {
            currentIndex = e.detail.index
            const page = pages[currentIndex]
            gotoPath(`${base}/${page.id}`)
        })
    })
</script>
<style>
    
    mwc-tab-bar {
        --mdc-theme-primary: var(--digilean-main-menu-text);
        --mdc-text-transform: none;
        --mdc-typography-button-text-transform: none;
        --mdc-tab-color-default: var(--digilean-main-menu-text);
        --mdc-tab-text-label-color-default: var(--digilean-main-menu-text);
        /* --mdc-tab-stacked-height: 100px; */
    }
    fa-icon {
        --digilean-fa-icon-margin-bottom: 0.4rem;
    }
</style>
<!-- <nav class="nested-nav"> -->
<mwc-tab-bar bind:this={tabbarEl} activeIndex={currentIndex}>
    {#each pages as page, i}
        <mwc-tab hasImageIcon>
            <fa-icon slot="icon" class="size30" 
                icon={page.icon}
                title={page.label}>
            </fa-icon>
            <SiblingLink base={base} param={page.id} langCode="{page.label}" />
        </mwc-tab>
        <!-- <span class="nested-nav-item" class:selected={i === currentIndex}>
            <span class="icon"><Icon class="size30" icon={page.icon}/></span>
            <SiblingLink base={base} param={page.id} langCode="{page.label}" />
        </span> -->
    {/each}
</mwc-tab-bar>
<!-- </nav> -->
<div class="nested-view">
    {#if param}
        <svelte:component this={pages[currentIndex].component} param={param} />
    {:else}
        <svelte:component this={pages[currentIndex].component} />
    {/if}
</div>
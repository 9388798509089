import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import "@common/components/Comments/CommentedBy";
let ReplyableCommentView = class ReplyableCommentView extends LitElement {
    render() {
        if (this.comment) {
            if (this.comment.replies && this.comment.replies.length > 0) {
                return html `
                    <comment-simple-viewer .comment=${this.comment}></comment-simple-viewer>
                    <div class="replies">
                        ${this.comment.replies.map(r => {
                    return html `
                                <replyable-comment-viewer .comment=${r}></replyable-comment-viewer>
                            `;
                })}
                    </div>
                `;
            }
            else {
                return html `
                    <comment-simple-viewer .comment=${this.comment}></comment-simple-viewer>
                `;
            }
        }
        return html `
            <span>N/A</span>
        `;
    }
};
ReplyableCommentView.styles = css `
        :host {
            display: block;
        }
        div.replies {
            margin: 0 0 0 0.5rem;
        }
    `;
__decorate([
    property({ attribute: false })
], ReplyableCommentView.prototype, "comment", void 0);
ReplyableCommentView = __decorate([
    customElement('replyable-comment-viewer')
], ReplyableCommentView);
export { ReplyableCommentView };

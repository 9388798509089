import oidc from "./digiLeanOidc";
export const initAuth = () => {
    oidc.initialize();
};
export const login = () => {
    oidc.login();
};
export const logout = () => {
    oidc.signOut();
};

import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let ImageViewer = class ImageViewer extends LitElement {
    constructor() {
        super(...arguments);
        this.url = "";
        this.alt = "";
        this.round = false;
    }
    render() {
        if (!this.url)
            return html `N/A`;
        return html `
            <img 
                src="${this.url}" 
                alt="${this.alt}"
                class="${this.round ? "round " : ""}"
            >
        `;
    }
};
ImageViewer.styles = css `
        :host {
            display: inline-block;
            width: 100%;
            max-width: 100%;
        }
        img {
            width: 100%;
            max-width: 100%;
        }
        .round {
            border-radius: 100%;
            box-shadow: 0 13px 26px rgba(#000, .2), 0 3px 6px rgba(#000, .2);
        }
    `;
__decorate([
    property({ attribute: true })
], ImageViewer.prototype, "url", void 0);
__decorate([
    property({ attribute: true })
], ImageViewer.prototype, "alt", void 0);
__decorate([
    property({ attribute: false })
], ImageViewer.prototype, "round", void 0);
ImageViewer = __decorate([
    customElement('image-viewer')
], ImageViewer);
export { ImageViewer };

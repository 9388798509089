import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
let DeviationSeverity = class DeviationSeverity extends LitElement {
    constructor() {
        super(...arguments);
        this.severity = 0;
    }
    getLabel() {
        switch (this.severity) {
            case 1:
                return "Low";
            case 2:
                return "Medium";
            case 3:
                return "High";
            case 0:
            default:
                return "Not specified";
        }
    }
    render() {
        const label = this.getLabel();
        const classes = {
            "icon": true,
            "notspecified": this.severity == 0,
            "low": this.severity == 1,
            "medium": this.severity == 2,
            "high": this.severity == 3
        };
        return html `
            <div class=${classMap(classes)} title="${label}">
                <fa-icon .icon=${faExclamationTriangle}></fa-icon>
            </div>
        `;
    }
};
DeviationSeverity.styles = css `
        :host {
            display: inline-block;
            margin: 0;
            padding: 0;
            width: auto;
            height: auto;
        }
        div.icon {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
        }

        fa-icon {
            color: var(--digilean-quaternary-background);
            --digilean-fa-icon-height: 1.6rem;
            --digilean-fa-icon-width: 1.6rem;
        }

        div.icon.notspecified fa-icon {
            color: var(--digilean-quaternary-background);
        }

        div.icon.low fa-icon {
            color: var(--digilean-primary);
        }

        div.icon.medium fa-icon {
            color: var(--digilean-button-warning);
        }

        div.icon.high fa-icon {
            color: var(--digilean-danger);
        }
    `;
__decorate([
    property({ attribute: false, state: true })
], DeviationSeverity.prototype, "severity", void 0);
DeviationSeverity = __decorate([
    customElement('deviation-severity')
], DeviationSeverity);
export { DeviationSeverity };

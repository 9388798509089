<script lang="ts">
    import { authUser, userProfile } from "@common/stores/userStore"
    import { logout } from "@common/authentication/authenticator"
    import Toggle from "@app/components/ThemeToggle.svelte"
</script>
<style>
    section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
    }
    div.main {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }
    div.user {
        margin: 1rem 0 1rem 0;
        text-align: left;
    }
    h3 {
        margin-bottom: 0.4rem;
    }
    p {
        margin-top: 0.2rem;
    }
    nav.menu-items {
        text-align: left;
    }
    /* nav.menu-items div {
        display: inline-flex;
        flex-direction: row;
        gap: 1rem;
        vertical-align: center;
        margin-bottom: 1rem;
    } */
    div.logout {
        display: inline-flex;
        flex-direction: column;
        flex-basis: 2rem;
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: auto;
    }
    div.logout button {
        flex-basis: 2rem;
        text-transform: capitalize;
        border-radius: 0;
        padding: 0;
    }
    div.language {
        margin-bottom: 2rem;
    }
</style>
<section>
    <div class="main">
        <div class="logo">
            <img src="https://digileanfiles.blob.core.windows.net/public-assets/logos/digilean-logo-96.png" alt="digilean logo">
        </div>
        <div class="user">
            <h3>{$authUser.displayName}</h3>
            <p>{$authUser.userName}</p>
            {#if $userProfile && $userProfile.user}
                <profile-image-viewer user={$userProfile.user}></profile-image-viewer>
            {/if}
        </div>
        <nav class="menu-items">
            <Toggle />
        </nav>
    </div>
    <div class="language">
        &nbsp;
    </div>
    <div class="logout">
        <button on:click={() => logout() }>LOG OUT</button>
    </div>
</section>
<script lang="ts">
    import { onMount } from "svelte"
    import { mySuggestions, loadMySuggestions } from "@common/stores/suggestionStore"
    // import { faLightbulb } from "@fortawesome/free-regular-svg-icons"
    import SuggestionLink from "./SuggestionsLink.svelte"

    onMount(() => {
        loadMySuggestions()
    })
</script>

<style>
    article {
        font-size: 14px;
        background: var(--digilean-primary-background);
        color: var(--digilean-primary-text);
    }
    header {
        background: var(--digilean-success);
        color: var(--digilean-text-on-dark);
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
    }
    .title {
        font-weight: bold;
    }
</style>
<article>
    <header>
            <p class="title">My Suggestions</p>
            <p class="counter">{$mySuggestions.length} Suggestions</p>
    </header>
    {#if $mySuggestions && Array.isArray($mySuggestions)}
        {#each $mySuggestions as suggestion}
            <SuggestionLink suggestion={suggestion} />
            <hr>
        {/each}
    {/if}

</article>

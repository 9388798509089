import rest from "@common/services/restService";
let cachedFileUrls = {};
const tryGetCachedUrl = (name, id) => {
    const key = name + id;
    const cache = cachedFileUrls[key];
    if (!cache)
        return null;
    let expireDate = new Date(cache.expire);
    let now = new Date();
    if (expireDate > now)
        return cache.data;
    Reflect.deleteProperty(cachedFileUrls, key);
    return null;
};
const addToCache = (name, id, data) => {
    if (!name || !id || !data)
        return;
    const key = name + id;
    const expire = getExpireDateFromUrl(data);
    cachedFileUrls[key] = { expire, data };
};
const getExpireDateFromUrl = (url) => {
    try {
        const urlObj = new URL(url);
        if (urlObj.searchParams && urlObj.searchParams.has("se")) {
            let expireDate = urlObj.searchParams.get("se");
            return expireDate;
        }
    }
    catch (e) {
        console.log(e);
    }
    let nowPlus9 = new Date();
    nowPlus9.setHours(nowPlus9.getHours() + 9);
    return nowPlus9.toISOString();
};
// tn_b8bb8fa7-d701-4e1e-8062-d63dab7d89a4-P1000081.JPG
// tn_b8bb8fa7-d701-4e1e-8062-d63dab7d89a4-P1000081.JPG
export const getFileUrl = async (fileName, fileId) => {
    const apiMethodUrl = "document/getFileUrl";
    const cachedUrl = tryGetCachedUrl(fileName, fileId);
    if (cachedUrl)
        return cachedUrl;
    const blobInfo = {
        blobName: fileName,
        fileInfoId: fileId
    };
    const fileUrl = await rest.post(apiMethodUrl, blobInfo);
    addToCache(fileName, fileId, fileUrl);
    return fileUrl;
};
export const getFile = async (id) => {
    const url = `document/getFile/${id}`;
    const file = await rest.get(url);
    return file;
};
export const getAll = async () => {
    const files = await rest.get("document");
    return files;
};
export const uploadImageToBlobStorageNoPreview = async (file) => {
    const url = "document/PostImageBlobUploadNoPreview";
    let fd = new FormData();
    fd.append('file', file);
    const res = await rest.post(url, fd);
    return res;
    // transformRequest: angular.identity,
    // headers: { 'Content-Type': undefined }
};
export const uploadFileBlobStorage = async (file) => {
    const url = "document/PostBlobUpload";
    const res = await rest.postFile(url, file);
    return res;
};

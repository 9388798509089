<script lang="ts">
    import {onMount} from "svelte"
    import { createEventDispatcher } from "svelte"
    import type { BoardTask } from "@api"
    import { updateTask } from "@common/stores/taskStore"
    import { yyyymmdd } from "@common/services/helper"
    const dispatch = createEventDispatcher()
    import TaskForm from "./TaskForm.svelte"
    export let task: BoardTask

    let disableSave = false
    let orgDate = ""
    let save = async () => {
        disableSave = true
        await updateTask(task, orgDate)
        dispatch("updatedtask", {id: task.id})
        // boardDate: "2021-06-24"
    }
    onMount(() => {
        orgDate = yyyymmdd(task.boardDate)
        task.boardDate = orgDate
    })

</script>
<style>
    article {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    header {
        background: var(--digilean-main-menu-background);
        color: var(--digilean-main-menu-text);
    }
    header h1 {
        margin: 0.5rem 0.3rem;
    }
    section {
        
        background: var(--digilean-secondary-background);
        color: var(--digilean-secondary-text);
        gap: 0.5rem;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }
    
</style>
<article>
    <header>
        <h1>Edit task</h1>
    </header>
    <section>
        {#if task}
            <TaskForm bind:task={task}/>
            <button on:click={save} disabled={disableSave}>Save</button>
        {/if}

    </section>
    
</article>
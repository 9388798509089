import { SuggestionState } from "@common/model/enums";
import rest from "@common/services/restService";
import { uploadFileBlobStorage, getFileUrl } from "@common/services/documentService";
let url = "suggestion";
let commentsUrl = `${url}/getComments/`;
let canEditUrl = `${url}/canedit/`;
export const getMySuggestions = async () => {
    const suggestions = await rest.get(`${url}/getmysuggestions`);
    return suggestions;
};
export const getCanEdit = async (id) => {
    const can = await rest.get(canEditUrl);
    return can;
};
export const getSuggestion = async (id) => {
    const suggestion = await rest.get(`${url}/${id}`);
    return suggestion;
};
export const getSuggestionComments = async (id) => {
    const comments = rest.get(`${commentsUrl}/${id}`);
    return comments;
};
export const uploadFileSuggestion = async (id, file) => {
    const resUpload = await uploadFileBlobStorage(file);
    const fileInfo = resUpload[0];
    const att = await rest.post(`${url}/{id}/AddAttachment`, fileInfo);
    return att;
};
export const getAttAndThumbnails = async (attachments) => {
    const attachmentImages = attachments.filter(a => a.fileInfo.isImage);
    const attachmentFiles = attachments.filter(a => !a.fileInfo.isImage);
    const images = await Promise.all(attachmentImages.map(async (a) => {
        if (a.fileInfo.isImage) {
            let url = "";
            if (a.fileInfo.hasPreview) {
                url = await getFileUrl(a.fileInfo.previewFileName, a.fileInfoId);
            }
            if (!url)
                url = await getFileUrl(a.fileInfo.fileName, a.fileInfoId);
            return {
                filename: a.fileInfo.fileName,
                extension: a.fileInfo.fileExtension,
                url
            };
        }
    }));
    const files = attachmentFiles.map(a => {
        const filename = a.fileInfo.name;
        const extension = a.fileInfo.fileExtension;
        const url = a.fileInfo.fileUrl;
        return { filename, extension, url };
    });
    return {
        images,
        files
    };
};
export const getNextSuggestionState = (current) => {
    if (!current)
        return SuggestionState.Planned;
    const currentEnum = SuggestionState[current];
    switch (currentEnum) {
        case SuggestionState.Suggested:
            return SuggestionState.Implemented;
        case SuggestionState.Planned:
        case SuggestionState.InProgress:
            return SuggestionState.Implemented;
        case SuggestionState.Implemented:
        case SuggestionState.Evaluated:
            return SuggestionState.Archived;
        case SuggestionState.Archived:
        default:
            return SuggestionState.Planned;
    }
};

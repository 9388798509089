import { authUser } from "@common/stores/userStore";
import * as service from "@common/services/taskService";
import { BehaviorSubject } from "rxjs";
authUser.subscribe(user => {
    if (user.loggedIn)
        loadMyTasks();
});
const myTasksSubject = new BehaviorSubject({ today: [], overdue: [], future: [] });
export const myTasks = myTasksSubject.asObservable();
export const loadMyTasks = async () => {
    const myTasks = await service.getMyTasks();
    myTasksSubject.next(myTasks);
};
export const updateTask = async (task, orgDate) => {
    const ids = await service.updateTask(task);
    if (task.boardDate !== orgDate)
        await service.updateTaskDate(task.id, task.boardDate);
    loadMyTasks();
};
export const newTask = async (task) => {
    const id = await service.registerNewTask(task);
    loadMyTasks();
};
export const removeTask = async (taskId) => {
    await service.deleteTask(taskId);
    loadMyTasks();
};
export const getBoardTask = async (id) => {
    const task = await service.getBoardTask(id);
    return task;
};

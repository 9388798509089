<script lang="ts">
    import {onMount} from "svelte"
    import type { ImprovementSuggestion, ReplyableCommentsViewModel } from "@api"
    import * as service from "@common/services/suggestionService"

    export let suggestion: ImprovementSuggestion
    
    let commentsVm: ReplyableCommentsViewModel = { total:0, comments: []}
    onMount(() => {
        getComments()

    })
    let getComments = async () => {
        commentsVm = await service.getSuggestionComments(suggestion.id)
    }
    let saveTopLevel = async (e) => {
        console.log("save")
    //     const comment: BoardTaskComment = e.detail.comment
    //     comment.boardTaskId = task.id
    //     await service.postComment(comment)
    //     getComments()
    }
</script>
<style>
    top-level-comment {
        margin: 0.5rem 0.5rem 0 0.5rem;
    }
</style>
<top-level-comment on:postcomment={saveTopLevel}></top-level-comment>
{#each commentsVm.comments as comment}
    <replyable-comment-viewer comment={comment}></replyable-comment-viewer>
{/each}
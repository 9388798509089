<script lang="ts">
    import { onMount, onDestroy } from "svelte"
    import type { BoardTask } from "@api"
    import { newTask } from "@common/stores/taskStore"
    
    import { userProfile } from "@common/stores/userStore"
    import type { Subscription } from "rxjs"
    import { gotoPath } from "@app/routing/router"

    import TaskForm from "./TaskView/TaskForm.svelte"
    
    let task: BoardTask = { boardId: 0, title: "", text: "" }
    let imageElement
    let show = false
    
    let inputField

    let disableSave = false

    let save = () => {
        disableSave = true
        newTask(task)
        gotoPath("/")
    }
    let sub: Subscription

    onMount(() => {
        sub = userProfile.subscribe(up => {
            if (up.user) {
                task.responsibleUserId = up.user.id
                task.responsibleUser = up.user.fullName
                task.responsibleDisplayName = up.user.displayName
            }
        })
    })
    const readURL = () => {
        if (inputField.files && inputField.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                imageElement.src = e.target.result
                show = true
            }
            reader.readAsDataURL(inputField.files[0])
        }
    }
    
    
    onDestroy(() => sub.unsubscribe())
</script>
<style>
    article {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    header {
        background: var(--digilean-main-menu-background);
        color: var(--digilean-main-menu-text);
    }
    header h1 {
        margin: 0.5rem 0.3rem;
    }
    section {
        
        background: var(--digilean-secondary-background);
        color: var(--digilean-secondary-text);
        gap: 0.5rem;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }
    img {
        display: none;
        max-width: 100%;
    }
    img.show {
        display: inline-block;
    }
</style>
<article>
    <header>
        <h1>New task</h1>
    </header>
    <section>

        <TaskForm bind:task={task} />
        <p>
            <label for="cameraInput">Select image or photo</label>
            <input id="cameraInput" type="file" accept="image/*" capture="environment" bind:this={inputField} on:change={readURL} />
        </p>

        <button on:click={save} disabled={disableSave}>Save</button>
        <br />
        <img src="" alt="selected imagephoto" 
            class:show
            bind:this={imageElement}>
    </section>
    
</article>
const host = location.origin;
export const shareTask = (task) => {
    if (navigator.share) {
        navigator.share({
            title: task.title,
            text: 'Check out this task',
            url: `${host}/task/${task.id}`,
        })
            .then(() => {
            console.log('Successful share');
        })
            .catch((error) => {
            console.log('Error sharing', error);
        });
    }
};

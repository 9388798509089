import App from "./App.svelte";
import "@common/style/colors.css";
import "@common/style/theme.css";
import "./style/global.css";
import "@common/components";
import "@common/components/Formatters";
import "@common/components/Images";
import "@common/components/Comments";
import "@common/components/Toast/ToastComponent";
const app = new App({
    target: document.body
});
export default app;

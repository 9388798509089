import { getMemberBoards } from "@common/services/boardService";
import { authUser } from "@common/stores/userStore";
import { BehaviorSubject } from "rxjs";
const boardsSubject = new BehaviorSubject([]);
export const boardsMember = boardsSubject.asObservable();
export const getBoard = (id) => {
    const boards = boardsSubject.getValue();
    const board = boards.find(b => b.id === id);
    return board;
};
const getBoards = async () => {
    const boards = await getMemberBoards();
    boardsSubject.next(boards);
};
authUser.subscribe(user => {
    if (user.loggedIn)
        getBoards();
    else
        boardsSubject.next([]);
});

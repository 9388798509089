import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { userProfile } from "@common/stores/userStore";
let TopLevelComment = class TopLevelComment extends LitElement {
    connectedCallback() {
        super.connectedCallback();
        userProfile.subscribe(val => this.user = val);
    }
    post() {
        if (!this.commentEditor)
            this.commentEditor = this.shadowRoot.querySelector("comment-editor");
        const value = this.commentEditor.content;
        const comment = {
            comment: value,
            commentedBy: this.user.user.fullName,
            commentedByUserId: this.user.user.userId,
            level: 0,
            mentions: ''
        };
        this.postEvent(comment);
        this.commentEditor.content = "";
    }
    postEvent(comment) {
        var evt = new CustomEvent('postcomment', {
            detail: {
                comment
            }
        });
        this.dispatchEvent(evt);
    }
    render() {
        return html `
            <profile-image-viewer .user=${this.user.user}></profile-image-viewer>
            <div class="editor">
                <comment-editor></comment-editor>
                <div class="button">
                    <button @click=${this.post}>Add comment</button>
                </div>
            </div>
        `;
    }
};
TopLevelComment.styles = css `
        :host {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
        }
        
        button {
            color: var(--digilean-main-menu-text);
            outline-color: var(--digilean-primary-button);
            border-color: var(--digilean-primary-button);
            background: var(--digilean-primary-button);
            box-sizing: border-box;
            border-radius:5px;
            border-width: 1px;
            padding: 0.5rem 1rem;
            transition: background .2s linear;
        }
        button:hover {
            background: var(--digilean-blue-dark);
        }
        button:disabled {
            cursor: not-allowed;
            color: var(--digilean-disabled-color);
            outline-color: var(--digilean-primary-button);
            border-color: var(--digilean-primary-button);
            background: var(--digilean-disabled-background);	
        }
        div.editor {
            flex-basis: 50%;
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
        div.button {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    `;
TopLevelComment = __decorate([
    customElement('top-level-comment')
], TopLevelComment);
export { TopLevelComment };

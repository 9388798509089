let baseUrlEnv = "";
let authUrlEnv = "";
let fileStoreUrlEnv = "";
try {
    ///@ts-ignore
    if (import.meta && import.meta.env) {
        ///@ts-ignore
        baseUrlEnv = import.meta.env.VITE_DIGILEAN_BASE_URL;
        ///@ts-ignore
        authUrlEnv = import.meta.env.VITE_DIGILEAN_AUTH_URL;
        ///@ts-ignore
        fileStoreUrlEnv = import.meta.env.VITE_DIGILEAN_FILESTORAGE_URL;
    }
}
catch (e) { }
let baseUrl = "https://dev.digilean.tools";
if (baseUrlEnv)
    baseUrl = baseUrlEnv;
let authUrl = "https://authdev.digilean.tools";
if (authUrlEnv)
    authUrl = authUrlEnv;
let fileStoreUrl = "https://testdigileanfiles.blob.core.windows.net";
if (fileStoreUrlEnv)
    fileStoreUrl = fileStoreUrlEnv;
const apiurl = `${baseUrl}/api`;
const signalrUrl = `${baseUrl}/hubs`;
export default { baseUrl, apiurl, authUrl, fileStoreUrl, signalrUrl };

import { authUser } from "@common/stores/userStore";
import { getMyDeviations } from "@common/services/deviationService";
import { BehaviorSubject } from "rxjs";
const myDeviationsSubject = new BehaviorSubject([]);
export const myDeviations = myDeviationsSubject.asObservable();
export const loadMyDeviations = async () => {
    const md = await getMyDeviations();
    myDeviationsSubject.next(md);
};
authUser.subscribe(user => {
    if (user.loggedIn)
        loadMyDeviations();
    else
        myDeviationsSubject.next([]);
});

<script lang="ts">
    import { onDestroy } from "svelte"
    import { curRoute } from "@app/routing/router"

    let component = null
    let param = ""
    let action = ""

    const subscription = curRoute.subscribe(async route => {
        component = route.component;
        param = route.param;
        action = route.action;
    })

    onDestroy(() => subscription.unsubscribe());

</script>

<style>
    
    section.main-container, div.center-container {
        height: 100%;
    }
    
</style>

<section class="main-container">

    <div class="center-container">
        {#if param}
            {#if action}
                <svelte:component this={component} param={param} action={action} />
            {:else}
                <svelte:component this={component} param={param} />
            {/if}
        {:else}
            <svelte:component this={component} />
        {/if}
    </div>
    
</section>

<script lang="ts">
    import { onMount, onDestroy } from "svelte"
    import type { Subscription } from "rxjs"
    import { openMainMenu } from "@common/stores/appstate"
	import { tweened } from "svelte/motion"
	import { cubicOut } from "svelte/easing"
    import TouchGestures from "@common/services/TouchGestures"

    let touch:TouchGestures
    let menuEl: HTMLDivElement | null = null
    let sub: Subscription
    let open = false
    onMount(() => {
        touch = new TouchGestures(menuEl, () => console.log("none"), () => openMainMenu.next(false) )
        sub = openMainMenu.subscribe(value => {
            if (value) {
                slideIn()
                document.addEventListener("click", closeMenu)
            }
            else {
                slideOut()
                document.removeEventListener("click", closeMenu);
            }
        });
    })
    onDestroy(() => {
        sub.unsubscribe()
        touch.removeEvents()
    })

	const menuLeft = tweened(-80, {
		duration: 400,
		easing: cubicOut,
	})
	const slideIn = () => {
		menuLeft.set(0)
        open = true
	}
	const slideOut = () => {
		menuLeft.set(-80)
        open = false
	}
    const closeMenu = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if ($menuLeft == 0 && menuEl && e.target && !menuEl.contains(e.target)) {
            openMainMenu.next(false)
        }
        
    }
    
</script>

<style>
	* {
		box-sizing: border-box;
		color: var(--digilean-primary-text);
	}
    div.wrapper {
        position: fixed;
        z-index: 900;
        left: -100vw;
        top: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: transparent;
        transition: background-color .4s linear;
    }
    div.wrapper.open {
        left: 0;
    }
    div.wrapper.modal {
        background-color: rgba(0,0,0,0.4);
    }
	div.menu {
		background: var(--digilean-main-menu-background);
        color: var(--digilean-main-menu-text);
		width: 80vw;
		height: 100vh;
		position: absolute;
		top:0;
		left:-80vw;
		padding: 0;
		z-index: 1;
	}
</style>

<div class="wrapper" class:open={$menuLeft > -80} 
    class:modal={open}>
    <div class="menu" style="left: {$menuLeft}vw" bind:this={menuEl}>
        <slot></slot>
    </div>
</div>

<script lang="ts">
    export let param
    import NestedView from "@app/components/NestedView.svelte"
    import MyTasks from "./Tasks/MyTasksList.svelte"
    import MySuggestions from "./Improvements/MySuggestions.svelte"
    import MyDeviations from "./Deviations/MyDeviations.svelte"
    
    import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
    import { faCheckSquare, faLightbulb } from "@fortawesome/free-regular-svg-icons"
    import type { Page } from "@common/model/types"
    
    let pages: Page[] = [
        {
            id: "tasks",
            label: "Tasks",
            component: MyTasks,
            icon: faCheckSquare
        },{
            id: "improvements",
            label: "Improvements",
            component: MySuggestions,
            icon: faLightbulb
        },
        {
            id: "incidents",
            label: "Incidents",
            component: MyDeviations,
            icon: faExclamationTriangle
        }
    ]
    let currentIndex = 0
    let currentPage = pages[currentIndex]
    $: if (param) {
        currentPage = pages.find(p => p.id == param)
        currentIndex = pages.indexOf(currentPage)
        if (!currentPage) {
            currentIndex = 0
            currentPage = pages[currentIndex]
        }
    }
</script>

<NestedView base="/home" pages={pages} currentIndex={currentIndex}/>
<script lang="ts">
    import type { Deviation } from "@api"
    import { gotoPath } from "@app/routing/router"
    import { faPaperclip } from "@fortawesome/free-solid-svg-icons"
    import { faComments } from "@fortawesome/free-regular-svg-icons"
    export let deviation: Deviation = {}

    let gotoSuggestionView = () => {
        let path = `/deviation/${deviation.id}`
        gotoPath(path)
    }
</script>
<style>
    .wrapper {
        display: flex;
        flex-direction: row;
        color: var(--digilean-secondary-text);
        cursor: pointer;
        margin: 0.5rem 0;
    }
    .bold {
        font-weight: bold;
    }
    .state, .main {
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        gap: 1rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .state {
        flex-basis: 12%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: x-small;
        margin: 0 0.5rem;
    }
    .main {
        flex-basis: 60%;
    }
    .thenumbers {
        flex-basis: 25%;
        display: inline-flex;
        flex-direction: row;
        gap: 2rem;
    }
    deviation-state {
        --digilean-icon-height: 1.6rem;
        --digilean-icon-width: 1.6rem;
    }
</style>
<div class="wrapper">
    <div class="state">
        <deviation-state status={deviation.status}></deviation-state>
        <deviation-severity severity={deviation.severity}></deviation-severity>
    </div>
    <div class="main" on:click={() => gotoSuggestionView()}>
        <div class="user">
            <span class="bold">{deviation.createdByUser}</span>
            <date-viewer date={deviation.incidentDate} fontsize="x-small"></date-viewer>
        </div>
        <div class="title">
            {deviation.title}
        </div>
        <div class="thenumbers">
            <div class="numbers">
                <fa-icon icon={faComments}></fa-icon>
                {deviation.comments.length}
            </div>
            <div class="numbers">
                <fa-icon icon={faPaperclip}></fa-icon>
                {deviation.attachmentCount}
            </div>
        </div>
    </div>
</div>
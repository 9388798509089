import { docTitle } from "@common/stores/appstate";
import { curRoutePath } from "@app/routing/router";
let rootTitle = "DigiLEAN pwa";
class PageState {
    constructor() {
        this.subscribe();
    }
    subscribe() {
        docTitle.subscribe(value => {
            this.setDocTitle(value);
        });
        curRoutePath.subscribe(value => {
            this.setDocTitleByPath();
        });
    }
    setDocTitle(title) {
        document.title = title;
        rootTitle = title;
    }
    async setDocTitleByPath() {
        document.title = `${rootTitle}`;
    }
}
export default new PageState();

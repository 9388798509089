import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
let DigiLeanButton = class DigiLeanButton extends LitElement {
    render() {
        return html `
            <button>
                <slot>Btn</slot>
            </button>
        `;
    }
};
DigiLeanButton.styles = css `
        :host {
            display: block;
        }
        button {
            cursor: pointer;
            color: var(--digilean-main-menu-text);
            outline-color: var(--digilean-primary-button);
            border-color: var(--digilean-primary-button);
            background: var(--digilean-primary-button);
            box-sizing: border-box;
            border-radius:5px;
            border-width: 1px;
            padding: 0.5rem 1rem;
            transition: background .2s linear;
        }
        button:hover {
            background: var(--digilean-blue-dark);
        }
        button:disabled {
            cursor: not-allowed;
            color: var(--digilean-disabled-color);
            outline-color: var(--digilean-primary-button);
            border-color: var(--digilean-primary-button);
            background: var(--digilean-disabled-background);	
        }
    `;
DigiLeanButton = __decorate([
    customElement('digilean-button')
], DigiLeanButton);
export { DigiLeanButton };

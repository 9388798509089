<script lang="ts">
    import { onMount, onDestroy } from "svelte"
    import { authUser } from "@common/stores/userStore"
    import { appInitialized } from "@common/stores/appstate"
    import { login } from "@common/authentication/authenticator"
    import anime from "animejs/lib/anime.es.js"

    let elPage
    let counter = 1
    let intervalId = 0
    const signin = () => {
        console.log("signin")
        login()
    }

    let timeline1, timeline2
    let loaded = false
    onMount(() => {

        
        intervalId = window.setInterval(function(){
            let prevclass = `loop${counter}`
            counter += 1
            if (counter > 10) {
                prevclass = "loop10"
                counter = 1
            }
            
            let newclass = `loop${counter}`
            elPage.classList.remove(prevclass)
            elPage.classList.add(newclass)
        }, 1000);
        
        timeline1 = anime.timeline({
            loop: false
        })

        // timeline2 = anime.timeline({
        //     loop: false
        // })

        timeline1
        // .add({
        //     targets: '#digilean-logo',
        //     rotate: [{value: "180deg", duration: 1000}],
        //     easing: "linear"
        // })
        .add({
            targets: '#digilean-logo',
            rotate: [{value: "2turn", duration: 1000}],
            easing: "linear"
        })
        // .add({
        //     targets: '#digilean-logo',
        //     rotate: [{value: "4turn", duration: 1000}],
        //     easing: "linear"
        // })
        // .add({
        //     targets: '#digilean-logo',
        //     rotate: [{value: "8turn", duration: 1000}],
        //     easing: "linear"
        // })
        // .add({
        //     targets: '#digilean-logo',
        //     rotate: [{value: "16turn", duration: 1000}],
        //     easing: "linear"
        // })
        .add({
            targets: '#digilean-logo',
            scale: [{value: [1,100], duration: 400}],
            // opacity: [{value: 0, duration: 400}],
            easing: "linear",
            complete: (anime) => {
                loaded = true
                //stopTV()
            }
        })
        .add({
            targets: '#digilean-logo',
            scale: [{value: 1, duration: 1000}],
            // opacity: 1,
            easing: "linear"
        })
        

        
        // .add({
        //     targets: '#nothing',
        //     duration: 2000
        // })
        // timeline2
        // .add({
        //     targets: '.signin .letter',
        //     opacity: 0,
        //     duration: 10
        // })
        // .add({
        //     targets: '.letter',
        //     translateX: anime.stagger(1000, {grid: [10, 1], from: 'center', axis: 'x'}),
        //     rotateZ: anime.stagger([0, 90], {grid: [10, 1], from: 'center', axis: 'x'}),
        //     delay: anime.stagger(100, {grid: [10, 1], from: 'center'}),
        //     easing: 'easeInOutQuad',
        //     duration: 2000
        // })
        // .add({
        //     targets: '.signin .letter',
        //     translateX: anime.stagger([500, 0], {grid: [10, 1], from: 'center', axis: 'x'}),
        //     opacity: 1,
        //     scale: 0.1,
        //     delay: anime.stagger(100, {grid: [10, 1], from: 'center'}),
        //     easing: 'easeInOutQuad',
        //     duration: 1000
        // });
    })

    onDestroy(() => {
        clearInterval(intervalId)
    })
</script>
<style>
    .landing-page {
        z-index: 10;
		height: 100%;
		display: flex;
        flex-direction: column;
		align-items: center;
		justify-content: center;
        background-color: var(--digilean-grey-light);
        --voronoi-cell-colors: #2f4050, #1c84c6, #0099de;
        --voronoi-number-of-cells: 30;
        --voronoi-line-color: #11171d;
        --voronoi-line-width: 3;
        
        --logo-top-left-color: #777;
        --logo-top-right-color: #444;
        --logo-bottom-left-color: #444;
        --logo-bottom-right-color: #444;
        max-width: 100%;
        overflow-y: hidden;
	}
    .landing-page.loaded {
        z-index: 10;
        background-color: var(--digilean-primary-background);
        --logo-top-left-color: #000;
        --logo-top-right-color: #000;
        --logo-bottom-left-color: #000;
        --logo-bottom-right-color: #000;
	}
    #digilean-logo {
        z-index: 10;
        height: 200px;
        width: 200px;
        margin-bottom: 5rem;
    }
    button {
        font-size: 3rem;
        z-index: 10;
        visibility: hidden;
    }
    button.loaded {
        visibility: visible;
    }
    .loading {
        z-index: 10;
    }
    .letter {
        display: inline-block;
        font-size: 3rem;
        color: white;
    }
    #tv {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000;
    }

</style>
<canvas id="tv">

</canvas>
<section class="landing-page" class:loaded bind:this={elPage}>
    <svg id="digilean-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5820 5820">
        <g id="logo">
            <path id="top-left"
                fill="var(--logo-top-left-color)"
                d="M 605,2813 C 358,2689 153,2586 151,2584 c -2,-2 2,-40 8,-86 63,-438 261,-909 533,-1265 410,-538 1023,-921 1665,-1043 202,-38 533,-65 533,-44 0,3 -105,212 -232,464 l -233,459 217,434 c 119,239 214,438 211,441 -4,3 -25,6 -48,6 -66,0 -224,44 -318,89 -202,97 -358,257 -472,486 -19,38 -35,47 -490,276 l -470,237 z" />
            <path id="top-right"
                fill="var(--logo-top-right-color)"
                d="m 5195,2678 -460,-230 -432,216 c -238,119 -435,216 -437,216 -2,0 -6,-33 -9,-72 -26,-314 -237,-618 -532,-763 l -89,-44 -232,-463 -232,-463 229,-457 c 208,-416 231,-458 253,-458 50,0 296,51 417,85 1040,302 1810,1184 1969,2255 23,153 38,410 24,409 -5,0 -216,-104 -469,-231 z" />
            <path id="bottom-left"
                fill="var(--logo-bottom-left-color)"
                d="M 2390,5635 C 1966,5554 1575,5381 1231,5122 1090,5016 858,4792 748,4656 395,4218 191,3712 140,3145 c -12,-130 -13,-215 -3,-215 4,0 211,102 460,228 l 452,227 436,-217 c 239,-120 439,-218 444,-218 4,0 11,30 14,68 29,326 266,646 575,776 l 56,24 235,463 235,464 -230,455 -230,455 -35,2 c -19,1 -90,-9 -159,-22 z" />
            <path id="bottom-right"
                fill="var(--logo-bottom-right-color)"
                d="m 3154,5212 234,-467 -214,-425 c -117,-234 -213,-430 -213,-435 -1,-6 37,-16 82,-23 308,-45 580,-244 721,-529 l 33,-66 459,-233 c 252,-129 468,-234 478,-234 15,0 899,440 914,455 13,13 -57,341 -105,490 -313,981 -1124,1699 -2133,1890 -110,21 -336,45 -423,45 h -67 z" />
        </g>
    </svg>
    {#if $authUser}
        {JSON.stringify($authUser)}
    {/if}
    {#if $appInitialized && !$authUser.loggedIn && loaded}
        <p></p>
    {:else}
        <div class="loading">
            <span class="letter">L</span>
            <span class="letter">o</span>
            <span class="letter">a</span>
            <span class="letter">d</span>
            <span class="letter">i</span>
            <span class="letter">n</span>
            <span class="letter">g</span>
            <span class="letter">.</span>
            <span class="letter">.</span>
            <span class="letter">.</span>
        </div>
    {/if}
    <button class="signin" on:click={signin} class:loaded>
        <span class="letter">S</span>
        <span class="letter">i</span>
        <span class="letter">g</span>
        <span class="letter">n</span>
        <span class="letter"></span>
        <span class="letter"></span>
        <span class="letter">i</span>
        <span class="letter">n</span>
    </button>
</section>
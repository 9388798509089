<script lang="ts">
    import { onMount } from "svelte"
    import { getDeviation } from "@common/services/deviationService"
    import { faExclamationTriangle, faComment, faPaperclip } from "@fortawesome/free-solid-svg-icons"
    import type { Deviation } from "@api"

    import Info from "./Info.svelte"
    import Comments from "./Comments.svelte"
    import Attachments from "./Attachments.svelte"

    export let param: number
    let tabbarEl: HTMLElement

    let deviation: Deviation = {
        comments: [],
        attachmentCount: 0
    }
    let activeIndex = 0
    onMount(() => {
        tabbarEl.addEventListener("MDCTabBar:activated", (e: any) => {
            activeIndex = e.detail.index
        })
    })

    let fetch = async () => {
        try {
            deviation = await getDeviation(param)
        }
        catch (err) {
            console.log(err)
        }
    }
</script>

<article>
    <mwc-tab-bar bind:this={tabbarEl}>
        <mwc-tab label="Incident" icon="info" stacked hasImageIcon>
            <fa-icon slot="icon" 
                icon={faExclamationTriangle}
                title="Incident">
            </fa-icon>
        </mwc-tab>
        <mwc-tab label="Comments" icon="message" stacked hasImageIcon>
            <fa-icon slot="icon" counter="{deviation.comments.length}"
                icon={faComment}
                title="Comments">
            </fa-icon>
        </mwc-tab>
        <mwc-tab label="Attachments" icon="link" stacked hasImageIcon>
            <fa-icon slot="icon" counter="{deviation.attachmentCount}"
                icon={faPaperclip}
                title="attachments">
            </fa-icon>
        </mwc-tab>
    </mwc-tab-bar>
    <section>
        {#if param}
            {#await fetch()}
                <p>Fetching...</p>
            {:then}
                
                {#if activeIndex == 1}
                    <Comments deviation={deviation} />
                {:else if activeIndex == 2}
                    <Attachments deviation={deviation} />
                {:else}
                    <Info deviation={deviation} />
                {/if}
                
            {:catch error}
                <p style="color: red">{error.message}</p>
            {/await}
        {:else}
            <p>No id provided</p>
        {/if}
    </section>
</article>
<style>
    article {
        display: flex;
        flex-direction: column;
        color: var(--digilean-primary-text);
        height: 100%;
    }
    section {
        background: var(--digilean-primary-background);
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }
    mwc-tab-bar {
        --mdc-theme-primary: var(--digilean-main-menu-text);
        --mdc-text-transform: none;
        --mdc-typography-button-text-transform: none;
        --mdc-tab-color-default: var(--digilean-disabled);
        --mdc-tab-text-label-color-default: var(--digilean-disabled);
        /* --mdc-tab-stacked-height: 100px; */
    }
    fa-icon {
        --digilean-fa-icon-width: 1.2rem;
        --digilean-fa-icon-height: 1.2rem;
    }
</style>
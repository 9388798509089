import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let ProfileImageViewer = class ProfileImageViewer extends LitElement {
    constructor() {
        super(...arguments);
        this._imageUrl = "";
        this.user = {};
        this.initials = false;
    }
    render() {
        if (!this.user)
            return html `<div class="initials">N/A</div>`;
        this.title = `${this.user.fullName}`;
        if (this.initials && this.user.initials) {
            return html `<span>${this.user.initials}</span>`;
        }
        if (this.user.profileImageUrl) {
            return html `
                <image-viewer 
                    url="${this.user.profileImageUrl}" 
                    alt="profile badge"
                    .round=${true}
                    >
                </image-viewer>`;
        }
        if (this.user.displayName) {
            return html `<span>${this.user.displayName}</span>`;
        }
        if (this.user.initials) {
            return html `<span>${this.user.initials}</span>`;
        }
    }
};
ProfileImageViewer.styles = css `
        :host {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            height: var(--digilean-image-height, 3rem);
            width: var(--digilean-image-width, 3rem);
            
            border-radius: 50%;
            background: var(--digilean-primary-button);
            color: var(--digilean-main-menu-text);
            text-align: center;
            font-family: "Roboto Mono", monospace;
            cursor: pointer;
            border: 1px solid white;
        }
    `;
__decorate([
    property({ attribute: false })
], ProfileImageViewer.prototype, "user", void 0);
__decorate([
    property({ attribute: false })
], ProfileImageViewer.prototype, "initials", void 0);
ProfileImageViewer = __decorate([
    customElement('profile-image-viewer')
], ProfileImageViewer);
export { ProfileImageViewer };

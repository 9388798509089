<script lang="ts">
    import {onMount} from "svelte"
    import type { BoardTask } from "@api"
    import { canEditTask, AddAttachment, getAttAndThumbnails } from "@common/services/taskService"
    import { faGlasses, faCamera, faImages, faVideo } from "@fortawesome/free-solid-svg-icons"
    import type { FileView } from "@common/model/types"
    export let task: BoardTask
    
    let canEdit = false
    let images: FileView[] = []
    let files: FileView[] = []
    onMount(() => {
        canEditTask(task.id).then(val => canEdit = val)
        getAttachments()
    })

    const getAttachments = async () => {
        const atts = await getAttAndThumbnails(task.id)
        files = atts.files
        images = atts.images
    }

    const uploadFile = (e: any) => {
        const inputField = e.target
        if (inputField.files && inputField.files[0]) {
            inputField.classList.add("uploading")
            inputField.disabled = true
            const file = inputField.files[0]
            AddAttachment(task.id, file).then(f => {
                inputField.classList.remove("uploading")
                inputField.disabled = false
                inputField.value = ""
                getAttachments()
            })
        }
    }
</script>
<style>
    input.noshow {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    div.uploaders {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    div.button {
        cursor: pointer;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        
        height: 4rem;
        width: 4rem;
        
        background: var(--digilean-warning-light);
        border-radius: var(--digilean-border-radius);
        flex-basis: 4rem;
        flex-grow: 0;
        flex-shrink: 1;
    }
    div.button:not(input.uploading) {
        background: var(--digilean-secondary-background);
    }
    fa-icon {
        --digilean-fa-icon-width: 3.2rem;
        --digilean-fa-icon-height: 3.2rem;
    }
</style>
{#each images as img}
    <image-viewer url={img.url} alt={img.filename}></image-viewer>
{/each}
{#each files as file}
    <document-file-link extension={file.extension} filename={file.filename} url={file.url}></document-file-link>
{/each}
<br>
{#if canEdit}
    <div class="uploaders">
        <div class="button">
            <label for="cameraInput"><fa-icon icon={faCamera}></fa-icon></label>
            <input id="cameraInput" class="noshow" type="file" accept="image/*" capture="environment" on:change={uploadFile} />
        </div>
        <div class="button">
            <label for="galleryInput"><fa-icon icon={faImages}></fa-icon></label>
            <input id="galleryInput" class="noshow" type="file" accept="image/*" capture="filesystem" on:change={uploadFile} />
        </div>
        <div class="button">
            <label for="videoInput"><fa-icon icon={faVideo}></fa-icon></label>
            <input id="videoInput" class="noshow" type="file" accept="video/*" capture="environment" on:change={uploadFile} />
        </div>
    </div>
{:else}
    <fa-icon icon={faGlasses}></fa-icon>
{/if}
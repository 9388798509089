<script lang="ts">
    import type { Subscription } from "rxjs"
    import {onMount, onDestroy} from "svelte"
    import type { SharedData } from "@common/model/types"
    import {sharedData, cleanSharedData, readyForData} from "@app/swloader"
    import { myTasks } from "@common/stores/taskStore"
    import { AddAttachment } from "@common/services/taskService"
    import type { UserTask } from "@api"
    import { gotoPath } from "@app/routing/router"
    // import type { BoardTask } from "../../api"
    // let parsedUrl: URL
    // let params: URLSearchParams
    let imageElement
    let shared: SharedData
    
    let userstasks: UserTask[] = []
    let show = false
    //let task: BoardTask
    let data: SharedData = {}
    let subs: Subscription[] = []
    onMount(() => {
        // parsedUrl = new URL(window.location)
        // params = parsedUrl.searchParams
        let sub = sharedData.subscribe(data => {
            shared = data
            if (data.file) {
                if (!imageElement)
                    imageElement = document.querySelector("#imageSharedPreview")
                const reader = new FileReader()
                reader.onload = (e) => {
                    console.log(e)
                    imageElement.src = e.target.result
                    show = true
                }
                reader.readAsDataURL(data.file as File)
            }
        })
        subs.push(sub)
        let sub2 = myTasks.subscribe(my => {
            userstasks = []
            if (my.future && my.future.length > 0)
                userstasks = [...my.future]

            if (my.overdue && my.overdue.length > 0)
                userstasks = [...userstasks, ...my.overdue]

            if (my.today && my.today.length > 0)
                userstasks = [...userstasks, ...my.today]
        })
        subs.push(sub2)
        readyForData()
    })
    let taskId = 0
    const setTask = e => {
        taskId = e.target.value
    }
    let addToTask = async () => {
        console.log(`add to taskid ${taskId}`)
        if (!taskId)
            return
        await AddAttachment(taskId, shared.file as File)
        cleanSharedData()
        gotoPath(`/task/${taskId}`)
    }
    onDestroy(() => {
        subs.map(s => s.unsubscribe())
    })
</script>
<style>
    article {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    header {
        background: var(--digilean-main-menu-background);
        color: var(--digilean-main-menu-text);
    }
    header h1 {
        margin: 0.5rem 0.3rem;
    }
    section {
        background: var(--digilean-secondary-background);
        color: var(--digilean-secondary-text);
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }
    img {
        display: none;
        max-width: 100%;
    }
    img.show {
        display: inline-block;
    }
</style>
<article>
    <header>
        <h1>Shared content from other app</h1>
    </header>
    <section>
        
        {#if !shared}
            <p>Incoming shared data...</p>
        {:else if shared && shared.file}
            <mwc-select label="Tasks" on:change={setTask}>
                {#each userstasks as task}
                    <mwc-list-item 
                        value="{`${task.id}`}">
                        {task.title}
                    </mwc-list-item>
                {/each}
            </mwc-select>    
            <button on:click={addToTask}>Add to task</button>
            <img alt="shared" id="imageSharedPreview" bind:this={imageElement} class:show>
        {:else}
            <p>title: {shared.title}</p>
            <p>text: {shared.text}</p>
            <p>url: {shared.url}</p>
        {/if}
    </section>
</article>

import rest from "@common/services/restService";
import { uploadFileBlobStorage, getFileUrl } from "@common/services/documentService";
import { getDayOfWeek } from "@common/services/helper";
export const getMyTasks = async () => {
    const offset = getTimeZoneOffset();
    const my = await rest.get(`task/getmytasks/${offset}`);
    return my;
};
const getTimeZoneOffset = () => {
    var timeNow = new Date();
    var offset = timeNow.getTimezoneOffset() / 60 * (-1);
    return offset;
};
export const getBoardTask = async (id) => {
    const task = await rest.get(`task/${id}`);
    return task;
};
export const registerNewTask = async (task) => {
    if (task.boardId === 0)
        task.boardId = null;
    const id = await rest.post("task", task);
    return id;
};
export const canEditTask = async (id) => {
    const canEdit = await rest.get(`task/CanEdit/${id}`);
    return canEdit;
};
export const updateTask = async (task) => {
    const ids = await rest.put(`task/${task.id}`, task);
    return ids;
};
export const updateTaskDate = async (id, date) => {
    const dayOfWeek = `${getDayOfWeek(date)}`;
    const opts = {
        date,
        dayOfWeek
    };
    const moved = await rest.put(`task/${id}/UpdateDate`, opts);
    return moved;
};
export const deleteTask = async (id) => {
    await rest.remove(`task/${id}`);
};
export const getTaskCommentWithReplies = async (id) => {
    const comments = await rest.get(`task/GetCommentWithReplies/${id}`);
    return comments;
};
export const postComment = async (comment) => {
    const cmt = await rest.post(`task/${comment.boardTaskId}/addcomment`, comment);
    return cmt;
};
export const GetTaskComments = async (id) => {
    const comments = await rest.get(`task/GetComments/${id}`);
    return comments;
};
export const GetAttachments = async (id) => {
    const attachments = rest.get(`task/GetAttachments/${id}`);
    return attachments;
};
export const AddAttachment = async (id, file) => {
    const resUpload = await uploadFileBlobStorage(file);
    const fileInfo = resUpload[0];
    const att = await rest.post(`task/${id}/AddAttachment`, fileInfo);
    return att;
};
export const getAttAndThumbnails = async (taskid) => {
    const allAttachments = await GetAttachments(taskid);
    if (!allAttachments || !Array.isArray(allAttachments) || allAttachments.length == 0)
        return;
    const attachmentImages = allAttachments.filter(a => a.fileInfo.isImage);
    const attachmentFiles = allAttachments.filter(a => !a.fileInfo.isImage);
    const images = await Promise.all(attachmentImages.map(async (a) => {
        if (a.fileInfo.isImage) {
            let url = "";
            if (a.fileInfo.hasPreview) {
                url = await getFileUrl(a.fileInfo.previewFileName, a.fileInfoId);
            }
            if (!url)
                url = await getFileUrl(a.fileInfo.fileName, a.fileInfoId);
            return {
                filename: a.fileInfo.fileName,
                extension: a.fileInfo.fileExtension,
                url
            };
        }
    }));
    const files = attachmentFiles.map(a => {
        const filename = a.fileInfo.name;
        const extension = a.fileInfo.fileExtension;
        const url = a.fileInfo.fileUrl;
        return { filename, extension, url };
    });
    return {
        images,
        files
    };
};

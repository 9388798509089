import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
let DeviationState = class DeviationState extends LitElement {
    constructor() {
        super(...arguments);
        this.status = 0;
    }
    getLabel() {
        switch (this.status) {
            case 10:
                return "In progress";
            case 20:
                return "Resolved";
            case 30:
                return "Rejected";
            case 0:
            default:
                return "New";
        }
    }
    render() {
        const label = this.getLabel();
        const classes = {
            "icon": true,
            "new": this.status == 0,
            "inprogress": this.status == 10,
            "resolved": this.status == 20,
            "rejected": this.status == 30
        };
        return html `
            <div class=${classMap(classes)}>
                <svg id="check" viewbox="0 0 13 13">
                    <polyline points="2 6 5 10 11 3"></polyline>
                </svg>
            </div>
            <span>${label}</span>
        `;
    }
};
DeviationState.styles = css `
        :host {
            display: inline-block;
            margin: 0;
            padding: 0;
            width: auto;
            height: auto;
        }
        div.icon {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            background: var(--digilean-quaternary-background);
            color: var(--digilean-quaternary-background);
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            border-radius: 50%;
        }

        div.icon.new {
            background: var(--digilean-quaternary-background);
        }
        div.icon.new svg#check {
            stroke: var(--digilean-quaternary-background);
        }

        div.icon.inprogress {
            background: var(--digilean-primary);
        }
        div.icon.inprogress svg#check {
            stroke: var(--digilean-primary);
        }

        div.icon.resolved {
            background: var(--digilean-danger);
        }
        div.icon.resolved svg#check {
            stroke: var(--digilean-white);
        }

        div.icon.rejected {
            background: var(--digilean-button-warning);
        }
        div.icon.rejected svg#check {
            stroke: var(--digilean-button-warning);
        }
        
        svg#check {
            height: calc(var(--digilean-icon-height, 2rem) * 0.7);
            width: calc(var(--digilean-icon-width, 2rem) * 0.7);
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: var(--digilean-white);
        }
    `;
__decorate([
    property({ attribute: false, state: true })
], DeviationState.prototype, "status", void 0);
DeviationState = __decorate([
    customElement('deviation-state')
], DeviationState);
export { DeviationState };

import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
let DateViewer = class DateViewer extends LitElement {
    constructor() {
        super(...arguments);
        this._dateString = "";
        this.formatter = Intl.DateTimeFormat("nb-NO", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    }
    get date() {
        return this._dateString;
    }
    set date(value) {
        this._dateString = value;
        this._date = new Date(value);
    }
    render() {
        let fontsize = "medium";
        if (this.fontsize)
            fontsize = this.fontsize;
        if (!this._date)
            return html `N/A`;
        let formattedDate = "";
        try {
            formattedDate = this.formatter.format(this._date);
        }
        catch (err) {
            console.log(`Cant format ${this._date}`);
        }
        return html `
            <span style="font-size: ${fontsize}">
                ${formattedDate}
            </span>
        `;
    }
};
DateViewer.styles = css `
        :host {
            display: inline-block;
        }
    `;
__decorate([
    state()
], DateViewer.prototype, "_date", void 0);
__decorate([
    property({ attribute: true })
], DateViewer.prototype, "fontsize", void 0);
DateViewer = __decorate([
    customElement('date-viewer')
], DateViewer);
export { DateViewer };

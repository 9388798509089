import Home from "@app/views/Home.svelte";
import About from "@app/views/About.svelte";
import User from "@app/views/User.svelte";
import NewTask from "@app/views/Tasks/NewTask.svelte";
import NewMenu from "@app/views/New/Index.svelte";
import TaskView from "@app/views/Tasks/TaskView/Index.svelte";
import ImprovementsView from "@app/views/Improvements/DetailsView/Index.svelte";
import DeviationView from "@app/views/Deviations/DetailsView/Index.svelte";
import Network from "@app/views/Network.svelte";
import ShareTarget from "@app/views/ShareTarget.svelte";
const routes = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "home",
        component: Home,
    },
    {
        path: "about",
        component: About
    },
    {
        path: "user",
        component: User
    },
    {
        path: "new",
        component: NewMenu
    },
    {
        path: "new-task",
        component: NewTask
    },
    {
        path: "task",
        component: TaskView
    },
    {
        path: "improvement",
        component: ImprovementsView
    },
    {
        path: "deviation",
        component: DeviationView
    },
    {
        path: "network",
        component: Network
    },
    {
        path: "sharetarget",
        component: ShareTarget
    }
];
export default routes;

import { getAllUsers, getUsersProfileImage, getProfileImageFilestore } from "@common/services/userService";
import { authUser } from "@common/stores/userStore";
import { BehaviorSubject } from "rxjs";
const allUsersSubject = new BehaviorSubject([]);
export const allUsers = allUsersSubject.asObservable();
const setAllUsers = async () => {
    let users = await getAllUsers();
    allUsersSubject.next(users);
    syncProfileImages();
};
const syncProfileImages = async () => {
    const userspi = await getUsersProfileImage();
    const currUsers = allUsersSubject.getValue();
    const updatedWithPi = currUsers.map(u => {
        const pi = userspi.find(p => p.userId == u.userId);
        if (pi && pi.fileName) {
            u.profileImageUrl = getProfileImageFilestore("ExtraSmall", pi.fileName);
        }
        return u;
    });
    allUsersSubject.next(updatedWithPi);
};
export const getUser = (id) => {
    const all = allUsersSubject.getValue();
    const user = all.find(u => u.id == id);
    return user;
};
authUser.subscribe(user => {
    if (user.loggedIn)
        setAllUsers();
});

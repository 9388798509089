<script lang="ts">
    import {onMount} from "svelte"
    import { getBoardTask } from "@common/stores/taskStore"
    import Info from "./TaskInfo.svelte"
    import Comments from "./TaskComments.svelte"
    import Attachments from "./TaskAttachments.svelte"
    import { faCheckSquare, faComment, faPaperclip } from "@fortawesome/free-solid-svg-icons"
    import type { BoardTask } from "@api"
    // import { faComment } from "@fortawesome/free-regular-svg-icons"
    export let param: number
    let tabbarEl: HTMLElement
    let activeIndex = 0
    let task: BoardTask = { commentCount: 0, attachmentCount: 0}
	onMount(async () => {
        tabbarEl.addEventListener("MDCTabBar:activated", (e) => {
            activeIndex = e.detail.index
        })
    })

    let getTask = async () => {
        try {
            task = await getBoardTask(param)
            if (!task) {
                task = {id: param, commentCount: 0, attachmentCount: 0}
            }
            return task
        }
        catch (err) {
            console.log(err)
            task = {id: param, commentCount: 0, attachmentCount: 0}
        }
    }
</script>
<style>
    article {
        display: flex;
        flex-direction: column;
        color: var(--digilean-primary-text);
        height: 100%;
    }
    section {
        background: var(--digilean-primary-background);
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }
    mwc-tab-bar {
        --mdc-theme-primary: var(--digilean-main-menu-text);
        --mdc-text-transform: none;
        --mdc-typography-button-text-transform: none;
        --mdc-tab-color-default: var(--digilean-disabled);
        --mdc-tab-text-label-color-default: var(--digilean-disabled);
        /* --mdc-tab-stacked-height: 100px; */
    }
    fa-icon {
        --digilean-fa-icon-width: 1.2rem;
        --digilean-fa-icon-height: 1.2rem;
    }
</style>
<article>
    <mwc-tab-bar bind:this={tabbarEl}>
        <mwc-tab label="Task" icon="info" stacked hasImageIcon>
            <fa-icon slot="icon" 
                icon={faCheckSquare}
                title="Task">
            </fa-icon>
        </mwc-tab>
        <mwc-tab label="Comments" icon="message" stacked hasImageIcon>
            <fa-icon slot="icon" counter="{task.commentCount}"
                icon={faComment}
                title="Comments">
            </fa-icon>
        </mwc-tab>
        <mwc-tab label="Attachments" icon="link" stacked hasImageIcon>
            <fa-icon slot="icon" counter="{task.attachmentCount}"
                icon={faPaperclip}
                title="attachments">
            </fa-icon>
        </mwc-tab>
    </mwc-tab-bar>
    <section>
        {#if param}
            {#await getTask()}
                <p>Fetching...</p>
            {:then}
                
                {#if activeIndex == 1}
                    <Comments task={task} />
                {:else if activeIndex == 2}
                    <Attachments task={task} />
                {:else}
                    <Info task={task} />
                {/if}
                
            {:catch error}
                <p style="color: red">{error.message}</p>
            {/await}
        {:else}
            <p>No task id provided</p>
        {/if}
    </section>
</article>

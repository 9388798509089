<script lang="ts">
    import type { ImprovementSuggestion } from "@api"
    import { gotoPath } from "@app/routing/router"
    import { faPaperclip, faHeart } from "@fortawesome/free-solid-svg-icons"
    import { faComments } from "@fortawesome/free-regular-svg-icons"
    export let suggestion: ImprovementSuggestion = {}

    let gotoSuggestionView = () => {
        let path = `/improvement/${suggestion.id}`
        
        gotoPath(path)
    }
</script>
<style>
    .suggestion-wrapper {
        display: flex;
        flex-direction: row;
        color: var(--digilean-secondary-text);
        cursor: pointer;
        margin: 0.5rem 0;
    }
    .bold {
        font-weight: bold;
    }
    .state, .main {
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        gap: 1rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .state {
        flex-basis: 12%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: x-small;
        margin: 0 0.5rem;
    }
    .main {
        flex-basis: 60%;
    }
    .thenumbers {
        flex-basis: 25%;
        display: inline-flex;
        flex-direction: row;
        gap: 2rem;
    }
    
</style>
<div class="suggestion-wrapper">
    <div class="state">
        <suggestion-state status={suggestion.status}></suggestion-state>
    </div>
    <div class="main" on:click={() => gotoSuggestionView()}>
        <div class="user">
            <span class="bold">{suggestion.suggestedBy}</span>
            <date-viewer date={suggestion.suggestionDate} fontsize="x-small"></date-viewer>
        </div>
        <div class="title">
            {suggestion.title}
        </div>
        <div class="thenumbers">
            <div class="numbers">
                <fa-icon icon={faHeart} class="heart"></fa-icon>
                {suggestion.likes.length}
            </div>
            <div class="numbers">
                <fa-icon icon={faComments}></fa-icon>
                {suggestion.comments.length}
            </div>
            <div class="numbers">
                <fa-icon icon={faPaperclip}></fa-icon>
                {suggestion.attachmentCount}
            </div>
        </div>
    </div>
</div>
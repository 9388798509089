import { toastStore } from "./toastStore";
class ToastService {
    info(msg) {
        const toast = {
            type: "info",
            size: "",
            position: "left",
            msg
        };
        toastStore.add(toast, 10);
    }
    biginfo(msg, click) {
        const toast = {
            type: "info",
            size: "big",
            position: "left",
            msg
        };
        if (click)
            toast.click = click;
        toastStore.add(toast, 20);
    }
    error(msg) {
        const toast = {
            type: "error",
            size: "normal",
            position: "right",
            msg
        };
        toastStore.add(toast);
    }
    success(msg) {
        const toast = {
            type: "success",
            size: "normal",
            position: "right",
            msg
        };
        toastStore.add(toast);
    }
}
export default new ToastService();

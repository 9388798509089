import { setPermission, notificationsQueue, notificationPermission } from "@common/stores/notifications";
//import { swRegistration } from "../swloader"
let swRegistration;
navigator.serviceWorker.ready.then((reg) => {
    swRegistration = reg;
});
const digiLeanIcon = "https://digileanfiles.blob.core.windows.net/public-assets/logos/digilean-logo-192.png";
const digiLeanBadge = "https://digileanfiles.blob.core.windows.net/public-assets/logos/digilean-logo-96.png";
const taskIcon = "https://digileanfiles.blob.core.windows.net/public-assets/icons/digilean-task-192.png";
let permission;
const initialize = () => {
    if (!("Notification" in window)) {
        console.log("notifications not supported");
        setPermission("denied");
        return;
    }
    permission = Notification.permission;
    setPermission(Notification.permission);
    if (Notification.permission === "granted") {
        notificationsQueue.subscribe(val => {
            if (val.title)
                notify(val);
        });
    }
    notificationPermission.subscribe(per => permission = per);
    // else if (Notification.permission === "denied") { 
    //     console.log("notifications denied")
    //     return
};
export const requestNotificationsPermission = async () => {
    permission = await Notification.requestPermission();
    //if (permission === 'denied' || permission === 'default')
    setPermission(permission);
};
export const sendNotification = () => {
    let options = {
        badge: digiLeanBadge,
        icon: digiLeanIcon,
        tag: "178637",
        body: "This is only a test message for task assigned"
    };
    try {
        swRegistration.showNotification("Test", options);
    }
    catch (error) {
        console.error(error);
    }
};
const notify = async (not) => {
    if (permission !== "granted")
        return;
    let options = {
        badge: digiLeanBadge,
        icon: taskIcon,
        tag: `${not.taskId}`,
        body: not.body,
    };
    swRegistration.showNotification(not.title, options);
};
initialize();

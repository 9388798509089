<script lang="ts">
    import { isOnline } from "@common/stores/appstate"
    import { signalRConnection } from "@common/stores/signalr"
    import { faWifi } from "@fortawesome/free-solid-svg-icons"

    let title = "online"
    $: title = $isOnline ? $signalRConnection.isConnected ? 'online' : 'signalR not connected' : 'offline'
</script>
<style>
    .online {
        color: var(--digilean-success);
    }
    .offline {
        color: var(--digilean-error);
    }
    .notsignalr {
        color: var(--digilean-warning);
    }
</style>
<fa-icon class="size30 {$isOnline ? $signalRConnection.isConnected ? 'online' : 'notsignalr' : 'offline'}" 
    icon={faWifi}
    title={title}>
</fa-icon>
import { authUser } from "@common/stores/userStore";
import { getMySuggestions } from "@common/services/suggestionService";
import { BehaviorSubject } from "rxjs";
const mySuggestionsSubject = new BehaviorSubject([]);
export const mySuggestions = mySuggestionsSubject.asObservable();
export const loadMySuggestions = async () => {
    const ms = await getMySuggestions();
    mySuggestionsSubject.next(ms);
};
authUser.subscribe(user => {
    if (user.loggedIn)
        loadMySuggestions();
    else
        mySuggestionsSubject.next([]);
});

import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
//import { faFileExcel } from "@fortawesome/free-regular-svg-icons"
import { faFileExcel, faFileWord, faFilePdf, faFile } from "@fortawesome/free-solid-svg-icons";
const wordExt = ["doc", "docx"];
const excelExt = ["xls", "xlsx"];
let DocumentFileLink = class DocumentFileLink extends LitElement {
    render() {
        let icon = faFile;
        let extension = "";
        if (this.extension) {
            extension = this.extension.replace(".", "");
            if (wordExt.includes(extension))
                icon = faFileWord;
            else if (excelExt.includes(extension))
                icon = faFileExcel;
            else if (extension == "pdf")
                icon = faFilePdf;
        }
        return html `
            <fa-icon .icon=${icon}></fa-icon>
            <span class="filename">${this.filename}</span>
        `;
    }
};
DocumentFileLink.styles = css `
        :host {
            display: inline-flex;
            flex-direction: row;
            width: 100%;
            max-width: 100%;
        }
        fa-icon {
            font-size: 20px;
            flex-basis: auto;
            flex-grow: 0;
            flex-shrink: 0;
        }
        .filename {
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 1;
        }
    `;
__decorate([
    property({ attribute: true })
], DocumentFileLink.prototype, "url", void 0);
__decorate([
    property({ attribute: true })
], DocumentFileLink.prototype, "filename", void 0);
__decorate([
    property({ attribute: true })
], DocumentFileLink.prototype, "extension", void 0);
DocumentFileLink = __decorate([
    customElement('document-file-link')
], DocumentFileLink);
export { DocumentFileLink };

<script lang="ts">
    import { getCurRoute, goto } from "@app/routing/router"
    export let append = "";
    export let route = "";
    export let langCode = "";
    export let color = "";
    export let background = "";
    export let icon = "";
    export let decor = false;

    let title = "";
    
    $ : if (langCode || append) {
        if (langCode) {
            title = langCode;
        }
        
        if (append) {
            route = `${getCurRoute()}${append}`;
        }
    }
</script>

<style>
    a {
        text-decoration: none;
        white-space: nowrap;
        color: var(--digilean-main-menu-text);
        margin: 0 0.1rem;
        line-height: 2rem;
    }
    a.decor {
        text-decoration: underline;
    }
    a.icon {
        font-size: 1.4rem;
    }
    a:hover {
        text-decoration: underline;
    }
    a.dark {
        color: var(--digilean-secondary-text);
    }
    a.backdark {
        background: var(--digilean-main-menu-background);
    }
</style>

{#if icon}
    <a href={route} class="icon" on:click={goto} 
        class:primary={color == "primary"}
        class:dark={color == "dark"} 
        class:backdark={background == "dark"}
        title={title}
        class:decor>
        <fa-icon class="sizeforty" icon={icon}></fa-icon>
    </a>
{:else}
    <a href={route} on:click={goto} 
        class:primary={color == "primary"}    
        class:dark={color == "dark"} 
        class:backdark={background == "dark"} 
        title={title}
        class:decor>
        <slot>{title}</slot>
    </a>
{/if}
import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
let SuggestionState = class SuggestionState extends LitElement {
    getLabel() {
        return this.status;
    }
    render() {
        const label = this.getLabel();
        const classes = {
            "icon": true,
            "suggested": this.status == "Suggested",
            "planned": this.status == "Planned",
            "inprogress": this.status == "InProgress",
            "evaluated": this.status == "Evaluated",
            "implemented": this.status == "Implemented",
            "archived": this.status == "Archived"
        };
        return html `
            <div class=${classMap(classes)}>
                <span>${label}</span>
            </div>
        `;
    }
};
SuggestionState.styles = css `
        :host {
            display: inline-block;
            margin: 0;
            padding: 0;
            width: auto;
            height: auto;
        }
        div.icon {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            background: var(--digilean-quaternary-background);
            color: var(--digilean-tertiary-text);
            padding: 0.2rem 0.5rem;
            border-radius: 3px;
        }
        div.icon.planned {
            background-color: var(--digilean-tertiary-text);
            color: var(--digilean-text-on-dark);
        }
        div.icon.inprogress {
            background: var(--digilean-primary);
            color: var(--digilean-text-on-dark);
        }
        div.icon.implemented {
            background: var(--digilean-button-success);
            color: var(--digilean-text-on-dark);
        }
        div.icon.evaluated, div.icon.archived {
            background: var(--digilean-button-warning);
            color: var(--digilean-text-on-dark);
        }
    `;
__decorate([
    property({ attribute: true, state: true })
], SuggestionState.prototype, "status", void 0);
SuggestionState = __decorate([
    customElement('suggestion-state')
], SuggestionState);
export { SuggestionState };

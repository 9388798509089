import { authUser } from "@common/stores/userStore";
import toast from "@common/components/Toast/toast";
import { loadingOrSaving } from "@common/stores/appstate";
import config from "@common/config";
const baseUrl = config.apiurl;
let authUserInfo;
authUser.subscribe(au => authUserInfo = au);
const jsonContentType = "application/json";
const get = async (url) => {
    const req = createRequest(url, "get", jsonContentType);
    return await http(req);
};
const post = async (url, data) => {
    const req = createRequest(url, "post", jsonContentType, data);
    return await http(req);
};
const postFile = async (url, file) => {
    const fullUrl = `${baseUrl}/${url}`;
    const bearer = getBearer();
    let fd = new FormData();
    fd.append('file', file);
    const req = {
        method: "POST",
        headers: {
            "Authorization": bearer
        },
        body: fd,
    };
    loadingOrSaving.next(true);
    const res = await fetch(fullUrl, req).catch((error) => {
        let errorFetchMsg = "Error posting file";
        console.error(error.message);
        toast.error(errorFetchMsg);
        loadingOrSaving.next(false);
        throw new Error(errorFetchMsg);
    });
    return resHandler(res);
};
const put = async (url, data) => {
    const req = createRequest(url, "put", jsonContentType, data);
    return await http(req);
};
const remove = async (url) => {
    const req = createRequest(url, "delete");
    return await http(req);
};
const createRequest = (url, method, contentType, data) => {
    const bearer = getBearer();
    const args = {
        method,
        headers: {
            "Content-Type": contentType,
            "Authorization": bearer
        }
    };
    if (data) {
        if (contentType === jsonContentType)
            args.body = JSON.stringify(data);
        else
            args.body = data;
    }
    const fullUrl = `${baseUrl}/${url}`;
    return new Request(fullUrl, args);
};
async function http(request) {
    loadingOrSaving.next(true);
    let errorFetchMsg;
    const res = await fetch(request)
        .catch((error) => {
        errorFetchMsg = "Error fetching";
        console.error(error.message);
        toast.error(errorFetchMsg);
        loadingOrSaving.next(false);
        throw new Error(errorFetchMsg);
    });
    return resHandler(res);
}
const resHandler = async (res) => {
    loadingOrSaving.next(false);
    let errorFetchMsg;
    if (res.ok) {
        const contentType = res.headers.get("content-type");
        if (res.status === 200 || res.status === 201) {
            if (contentType && contentType.includes("application/json")) {
                const json = await res.json();
                return json;
            }
            const text = await res.text();
            return text;
        }
        else {
            return "";
        }
    }
    else {
        console.error(`${res.statusText} (${res.status})`);
        errorFetchMsg = "Error fetching";
        if (res.status >= 400 && res.status < 500) {
            try {
                const pd = await res.json();
                console.log(pd);
                if (pd.title)
                    errorFetchMsg = pd.title;
                else
                    errorFetchMsg = `status${res.status}`;
            }
            catch (ex) {
                console.debug(ex);
            }
        }
        else {
            const message = await res.text();
            console.log(message);
        }
        toast.error(errorFetchMsg);
        throw new Error(errorFetchMsg);
    }
};
const getBearer = () => {
    if (authUserInfo.token) {
        return `Bearer ${authUserInfo.token}`;
    }
};
export default { get, post, postFile, put, remove };

<script lang="ts">
    import {onMount} from "svelte"
    import type { BoardTask, BoardTaskInfo, TaskBoardInfo } from "@api"
    // import { fapen } from "@fortawesome/free-regular-svg-icons"
    import { faPen, faTrash, faGlasses, faShareAlt } from "@fortawesome/free-solid-svg-icons"
    import { removeTask } from "@common/stores/taskStore"
    import { getBoardInfoFormatted } from "@common/services/boardService"
    import { gotoPath } from "@app/routing/router"
    import { canEditTask } from "@common/services/taskService"
    import { shareTask } from "@common/services/shareApi"
    import TaskEdit from "./TaskEdit.svelte"
    export let task: BoardTask
    let boardInfo: string
    
    let editMode = false
    let canEdit = false
    let delDialogEl
    let edit = () => {
        editMode = true
    }
    let updatedTask = (e) => {
        editMode = false
    }
    let openDeleteDialog = () => {
        delDialogEl.open = true
    }
    let share = () => {
        shareTask(task)
    }
    onMount(() => {
        getBoardInfoFormatted(task.id).then(info => {
            boardInfo = info
        })

        canEditTask(task.id).then(val => canEdit = val)
        delDialogEl.addEventListener("closing", (e) => {
            const action = e.detail.action
            if (action === "deleteTask") {
                removeTask(task.id)
                gotoPath("/")
            }
        })
    })
</script>
<style>
    .description {
        background: var(--digilean-secondary-background);
        color: var(--digilean-secondary-text);
        margin: 0.8rem;
        padding: 0.6rem;
    }
    div.topbar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 1rem;
        color: var(--digilean-secondary-text);
    }
    
    div.header {
        height: 3rem;
        line-height: 3rem;
        vertical-align: middle;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    h2 {
        padding: 0;
        margin: 0;
    }
    .placeholder {
        display: inline-block;
        width: 1rem;
    }
    
    menu-item.edit {
        --digilean-icon-color: var(--digilean-primary);
    }
    menu-item.delete {
        --digilean-icon-color: var(--digilean-danger);
    }
</style>
{#if task}
    {#if editMode}
        <TaskEdit bind:task={task} on:updatedtask={updatedTask} />
    {:else}
        <div class="topbar">
            <digilean-tri-state-box />
            <span>{task.responsibleUser}</span>
            <date-viewer date={task.boardDate} fontsize="x-small"></date-viewer>
        </div>
        <div class="header">
            <div class="placeholder"></div>
            <h2>{task.title}</h2>
            {#if canEdit}
                <popdown-menu>
                    <div slot="content" class="menu-content">
                        <menu-item icon={faShareAlt} title="Share" action={share} class="share"></menu-item>
                        <menu-item icon={faPen} title="Edit" action={edit} class="edit"></menu-item>
                        <menu-item icon={faTrash} title="Delete" action={openDeleteDialog} class="delete"></menu-item>
                    </div>
                </popdown-menu>
            {:else}
                <fa-icon icon={faGlasses}></fa-icon>
            {/if}
        </div>
        <div class="description">
            {@html task.text}
        </div>
        <div class="category">
            {boardInfo}
        </div>
    {/if}

{:else}
    <p></p>
{/if}
<mwc-dialog bind:this={delDialogEl}>
    <div>Delete task?</div>

    <mwc-button
        slot="primaryAction"
        dialogAction="deleteTask">
      Delete
    </mwc-button>
    <mwc-button
        slot="secondaryAction"
        dialogAction="cancel">
      Cancel
    </mwc-button>
</mwc-dialog>
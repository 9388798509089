import rest from "@common/services/restService";
import { uploadFileBlobStorage, getFileUrl } from "@common/services/documentService";
const url = "deviation";
export const getMyDeviations = async () => {
    const devs = await rest.get(`${url}/getMyDeviations`);
    return devs;
};
export const getDeviation = async (id) => {
    const dev = await rest.get(`${url}/${id}`);
    return dev;
};
export const getDeviationComments = async (id) => {
    const comments = rest.get(`${url}/GetComments/${id}`);
    return comments;
};
export const uploadFileToDeviation = async (id, file) => {
    const resUpload = await uploadFileBlobStorage(file);
    const fileInfo = resUpload[0];
    const att = await rest.post(`${url}/{id}/AddAttachment`, fileInfo);
    return att;
};
export const getAttAndThumbnails = async (attachments) => {
    const attachmentImages = attachments.filter(a => a.fileInfo.isImage);
    const attachmentFiles = attachments.filter(a => !a.fileInfo.isImage);
    const images = await Promise.all(attachmentImages.map(async (a) => {
        if (a.fileInfo.isImage) {
            let url = "";
            if (a.fileInfo.hasPreview) {
                url = await getFileUrl(a.fileInfo.previewFileName, a.fileInfoId);
            }
            if (!url)
                url = await getFileUrl(a.fileInfo.fileName, a.fileInfoId);
            return {
                filename: a.fileInfo.fileName,
                extension: a.fileInfo.fileExtension,
                url
            };
        }
    }));
    const files = attachmentFiles.map(a => {
        const filename = a.fileInfo.name;
        const extension = a.fileInfo.fileExtension;
        const url = a.fileInfo.fileUrl;
        return { filename, extension, url };
    });
    return {
        images,
        files
    };
};

<script lang="ts">
    import { onMount } from "svelte"
    import { myDeviations, loadMyDeviations } from "@common/stores/deviationStore"
    // import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
    import DeviationLink from "./DeviationLink.svelte"
    
    onMount(() => {
        loadMyDeviations()
    })
</script>

<style>
    article {
        font-size: 14px;
        background: var(--digilean-primary-background);
        color: var(--digilean-primary-text);
    }
    header {
        background: var(--digilean-danger);
        color: var(--digilean-text-on-dark);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title {
        font-weight: bold;
    }
    
</style>
<article>
    <header>
        <p class="title">My Incidents</p>
        <p class="counter">{$myDeviations.length} incidents</p>
    </header>
    {#if $myDeviations && Array.isArray($myDeviations)}
        {#each $myDeviations as deviation}
            <DeviationLink deviation={deviation} />
            <hr>
        {/each}
    {/if}

</article>

import { SvelteSubject } from "@app/store/reactive";
const curRoutePathSubject = new SvelteSubject("");
export const curRoutePath = curRoutePathSubject.asObservable();
export const curRoute = new SvelteSubject({ path: "/", component: null });
export const getCurRoute = () => {
    const cur = curRoutePathSubject.getValue();
    return cur;
};
export const goto = (e) => {
    e.preventDefault();
    const path = e.currentTarget.pathname;
    curRoutePathSubject.set(path);
};
export const gotoPath = (path) => {
    curRoutePathSubject.set(path);
};
window.addEventListener("popstate", (event) => {
    gotoPath(event.state.path);
});
const pushPathToHistory = (path) => {
    if (!path)
        return;
    if (window.location.pathname != path)
        window.history.pushState({ path }, "", window.location.origin + path);
};
curRoutePath.subscribe(pushPathToHistory);

export const formatLoggedInUser = (user) => {
    var _a, _b;
    let firstName = user.profile.given_name;
    let lastName = user.profile.family_name;
    let authUser = {
        loggedIn: true,
        customerId: user.profile.customer_id,
        userId: user.profile.sub,
        userName: user.profile.preferred_username,
        displayName: `${firstName} ${lastName}`,
        roles: getRole(user),
        token: user.access_token,
        idToken: user.id_token,
        refreshtoken: user.refresh_token
    };
    authUser.isAdmin = (_a = authUser.roles) === null || _a === void 0 ? void 0 : _a.includes("Admin");
    authUser.isKaizenAdmin = (_b = authUser.roles) === null || _b === void 0 ? void 0 : _b.includes("KaizenAdmin");
    return authUser;
};
const getRole = (user) => {
    if (!user.profile.role)
        return ["user"];
    return Array.isArray(user.profile.role) ? user.profile.role : [user.profile.role];
};

import { __decorate } from "tslib";
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
let FontawesomeIcon = class FontawesomeIcon extends LitElement {
    constructor() {
        super(...arguments);
        this._viewBox = "0 0 10 10";
        this._path = "";
        this.counter = "";
    }
    get icon() {
        return this._icon;
    }
    set icon(value) {
        this._icon = value;
        this.extractIcon();
    }
    extractIcon() {
        this._viewBox = `0 0 ${this._icon.icon[0]} ${this._icon.icon[1]}`;
        this._path = this._icon.icon[4];
    }
    render() {
        return html `
            <svg aria-hidden="true"
                class="fa-dl-icon"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="${this._viewBox}"
            >
                <path fill="currentColor" d="${this._path}" />
            </svg>
            
            ${this.counter ?
            html `<span class="badge">${this.counter}</span>` : ""}
        `;
    }
};
FontawesomeIcon.styles = css `
        :host {
            display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;
            width: var(--digilean-fa-icon-width, 1em);
            height: var(--digilean-fa-icon-height, 1em);
        }
        .fa-dl-icon {
            width: 100%;
            height: 100%;            
            overflow: visible;
            display: block;
            margin-bottom: var(--digilean-fa-icon-margin-bottom, 0);
        }
        span.badge {
            display: inline-block;
            position: absolute;
            top: -6px;
            right: -12px;
            width: calc(var(--digilean-fa-icon-width) * 0.8);
            height: calc(var(--digilean-fa-icon-width) * 0.8);
            line-height: calc(var(--digilean-fa-icon-width) * 0.8);
            font-family: "Roboto Mono", monospace;
            font-size: x-small;
            border-radius: 50%;
            text-align: center;
            background: var(--digilean-primary);
            color: var(--digilean-text-on-dark);
        }
    `;
__decorate([
    state()
], FontawesomeIcon.prototype, "_viewBox", void 0);
__decorate([
    state()
], FontawesomeIcon.prototype, "_path", void 0);
__decorate([
    property({ attribute: true })
], FontawesomeIcon.prototype, "counter", void 0);
FontawesomeIcon = __decorate([
    customElement('fa-icon')
], FontawesomeIcon);
export { FontawesomeIcon };

<script lang="ts">
    import {onMount} from "svelte"
    import { boardsMember } from "@common/stores/boardsStore"
    import { allUsers, getUser } from "@common/stores/usersStore"
    import type { BoardTask } from "@api"
    import { yyyymmdd } from "@common/services/helper"
    export let task: BoardTask = { boardId: 0 }

    let date = ""
    let titleEl: HTMLElement
    const setRes = (e: any) => {
        var userId = e.currentTarget.value
        task.responsibleUserId = userId
        const up = getUser(userId)
        task.responsibleUser = up.fullName
        task.responsibleDisplayName = up.displayName
    }
    const setDueDate = (e: any) => {
        task.boardDate = e.target.value
    }
    const setText = (e: any) => {
        task.text = e.target.value
    }
    const setBoard = (e: any) => {
        task.boardId = e.target.value
    }
    const setTitle = (e: any) => {
        task.title = e.target.value
    }
    onMount(() => {
        date = yyyymmdd(task.boardDate)
    })
</script>

<mwc-select label="Board" on:change={setBoard}>
    {#each $boardsMember as board}
        <mwc-list-item 
            value="{`${board.id}`}" 
            selected={!board.id && !task.boardId ? true : task.boardId == board.id ? true : false}>
            {board.name}
        </mwc-list-item>
    {/each}
</mwc-select>

<mwc-textfield label="Title" value="{task.title}" on:change={setTitle} bind:this={titleEl}></mwc-textfield>

<mwc-textarea label="Description" value={task.text} class="exercise-reps" on:change={setText}></mwc-textarea>

<input type="date" value={date} placeholder="Due date" on:change={setDueDate} />

<mwc-select label="Responsible" on:change={setRes}>
    <mwc-list-item></mwc-list-item>
    {#each $allUsers as u}
        <mwc-list-item 
            value="{u.id}"
            selected={u.id == task.responsibleUserId ? true : false}>
            {u.firstName} {u.lastName}
        </mwc-list-item>
    {/each}
</mwc-select>
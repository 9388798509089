import { Workbox } from "workbox-window";
import { updatedVersion, doUpdateVersion } from "@common/stores/appstate";
import { curRoutePath } from "@app/routing/router";
import { SvelteSubject } from "@app/store/reactive";
let wb;
let swRegistration;
const sharedDataSubject = new SvelteSubject({ title: "" });
export const sharedData = sharedDataSubject.asObservable();
export const cleanSharedData = () => {
    sharedDataSubject.next({ title: "" });
};
const setupWb = () => {
    wb = new Workbox("/sw.js");
    curRoutePath.subscribe(route => {
        if (route != "/")
            wb.update();
    });
    wb.addEventListener("waiting", (event) => {
        console.log(event);
        updatedVersion.next(true);
    });
    wb.addEventListener("controlling", (event) => {
        console.log(event);
        window.location.reload();
    });
    wb.addEventListener("message", (event) => {
        let data = event.data;
        sharedDataSubject.next(data);
        console.log(data);
    });
    wb.register().then(reg => swRegistration = reg);
    doUpdateVersion.subscribe(doUpdate => {
        if (doUpdate) {
            console.log("doupdate");
            wb.messageSkipWaiting();
        }
    });
};
export const readyForData = () => {
    wb.messageSW({ readyForData: true });
};
if ('serviceWorker' in navigator) {
    setupWb();
}
// if ('serviceWorker' in navigator) {
// 	window.addEventListener('load', function () {
// 		navigator.serviceWorker.register('/sw.js', {
// 			"scope": "/"
// 		}).then((registration) => {
// 			// Registration was successful
// 			console.log('ServiceWorker registration successful with scope: ', registration.scope);
// 		}, (err) => {
// 			// registration failed :(
// 			console.log('ServiceWorker registration failed: ', err);
// 		});
// 	});
// }

const connect = () => {
    return new Promise((resolve, reject) => {
        if (window.indexedDB) {
            const request = indexedDB.open("workbox-background-sync");
            request.onerror = function (event) {
                reject("Why didn't you allow my web app to use IndexedDB?!");
            };
            request.onsuccess = function (event) {
                resolve(request.result);
            };
        }
        reject("not supported");
    });
};
const getQueue = (db) => {
    return new Promise((resolve, reject) => {
        db.onerror = (ev) => {
            console.error("db error", ev);
            reject("db connect error");
        };
        const requestsTag = "requests";
        const tx = db.transaction([requestsTag], "readonly");
        tx.onerror = () => {
            console.log("transaction error");
            reject("db transaction error");
        };
        const store = tx.objectStore(requestsTag);
        const req = store.get("digilean-failed-posts");
        req.onsuccess = (event) => {
            db.close();
            resolve(req.result);
        };
        req.onerror = (err) => {
            console.log(`something went wrong when getting data from objectstore`, err);
            reject(err);
        };
    });
};
export const getReqQueue = async () => {
    const db = await connect();
    const res = await getQueue(db);
    return res;
};

<script lang="ts">
    import { onMount, onDestroy } from "svelte"
    import { authUser } from "@common/stores/userStore"
    import { parseToken, parseExpire } from "@common/services/tokenReader"
    import type { DigiLeanAuthUser } from "@common/model/types"
    import type { Subscription } from "rxjs"

    let token = ""
    let idToken = ""
    let expire = {}
    let sub: Subscription
    let user: DigiLeanAuthUser
    let userString = ""
    onMount(() => {
        sub = authUser.subscribe(u => {
            user = u
            if (user.loggedIn) {
                userString = JSON.stringify(user, null, 4);
                const tokenObj = parseToken(user.token)
                token = JSON.stringify(tokenObj, null, 4)
                const expireObj = parseExpire(tokenObj.exp)
                expire = JSON.stringify(expireObj, null, 4);
                const idTokenObj = parseToken(user.idToken)
                idToken = JSON.stringify(idTokenObj, null, 4);
            }
        })
        
    })
    onDestroy(() => sub.unsubscribe())
</script>
{#if user && user.loggedIn}
    <textarea cols="100" rows="30">{userString}</textarea>
    <textarea cols="100" rows="30">{token}</textarea>
    <textarea cols="100" rows="30">{idToken}</textarea>
    <p>
        {expire}
    </p>
{:else}
    <p>Not logged in</p>
{/if}
<script>
    import { isDarkTheme } from "@common/stores/appstate"
    import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons"
    export let text = "Toggle theme"
    const toggle = () => {
        const curVal = isDarkTheme.getValue()
        isDarkTheme.next(!curVal)
    };
</script>
<style>
    div {
        user-select: none;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 1rem;
        cursor: pointer;
    }
</style>
<div on:click={toggle} title="{text}">
    {#if $isDarkTheme}
        <fa-icon icon={faMoon}></fa-icon>
    {:else}
        <fa-icon icon={faSun}></fa-icon>
    {/if}
    <span>{text}</span>
</div>
